import * as constants from './constants';

export const fetchLearnersDocs = (data) => {
  return {
    type: constants.FETCH_LEARNERS_DOCS,
    data: data,
  };
};

export const fetchLearnersDocsSuccess = (data) => {
  return {
    type: constants.FETCH_LEARNERS_DOCS_SUCCESS,
    data: data,
  };
};

export const fetchLearnersDocsError = () => {
  return {
    type: constants.FETCH_LEARNERS_DOCS_ERROR,
  };
};

export const fetchActivities = (data) => {
  return {
    type: constants.FETCH_LEARNERS_GRAPH,
    data,
  };
};

export const fetchActivitiesSuccess = (data) => {
  return {
    type: constants.FETCH_LEARNERS_GRAPH_SUCCESS,
    data: data,
  };
};

export const fetchActivitiesSearchUtils = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA,
    data,
  };
};

export const fetchActivitiesSearchUtilsSuccess = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS,
    data: data,
  };
};

export const fetchActivitiesSearchUtilsError = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA_ERROR,
    data,
  };
};

export const fetchBatchUpdate = (data) => {
  return {
    type: constants.FETCH_BATCH_UPDATE,
    data: data,
  };
};

export const fetchBatchUpdateSuccess = (data) => {
  return {
    type: constants.FETCH_BATCH_UPDATE_SUCCESS,
    data: data,
  };
};

export const fetchBatchUpdateError = () => {
  return {
    type: constants.FETCH_BATCH_UPDATE_ERROR,
  };
};
