import React from "react";
import { Checkbox } from "antd";

const CheckboxComponent = ({ id, name, handleClick, isChecked }) => {
  return (
    <Checkbox id={id} name={name} onChange={handleClick} checked={isChecked} />
  );
};

export default CheckboxComponent;
