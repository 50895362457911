import * as constants from './constants';

export const fetchProgrammeTypes = (data) => {
  return {
    type: constants.FETCH_PROGRAMME_TYPES,
    data,
  };
};

export const fetchProgrammeTypesSuccess = (data) => {
  return {
    type: constants.FETCH_PROGRAMME_TYPES_SUCCESS,
    data: data,
  };
};

export const fetchProgrammeTypesError = (data) => {
  return {
    type: constants.FETCH_PROGRAMME_TYPES_ERROR,
    data: data,
  };
};

export const updateProgrammeTypes = (data) => {
  return {
    type: constants.UPDATE_PROGRAMME_TYPES,
    data: data,
  };
};

export const updateProgrammeTypesSuccess = (data) => {
  return {
    type: constants.UPDATE_PROGRAMME_TYPES_SUCCESS,
    data: data,
  };
};

export const updateProgrammeTypesError = () => {
  return {
    type: constants.UPDATE_PROGRAMME_TYPES_ERROR,
  };
};

export const updateProgrammeTypesOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_PROGRAMME_TYPES,
    data: data,
  };
};

export const updateProgrammeTypesOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_PROGRAMME_TYPES_SUCCESS,
    data: data,
  };
};

export const updateProgrammeTypesOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_PROGRAMME_TYPES_ERROR,
  };
};

export const deleteProgrammeTypes = (data) => {
  return {
    type: constants.DELETE_PROGRAMME_TYPES,
    data: data,
  };
};

export const deleteProgrammeTypesSuccess = (data) => {
  return {
    type: constants.DELETE_PROGRAMME_TYPES_SUCCESS,
    data: data,
  };
};

export const deleteProgrammeTypesError = () => {
  return {
    type: constants.DELETE_PROGRAMME_TYPES_ERROR,
  };
};

export const createProgrammeTypes = (data, id) => {
  return {
    type: constants.CREATE_PROGRAMME_TYPES,
    data: data,
  };
};

export const createProgrammeTypesSuccess = (data) => {
  return {
    type: constants.CREATE_PROGRAMME_TYPES_SUCCESS,
    data: data,
  };
};

export const createProgrammeTypesError = (id) => {
  return {
    type: constants.CREATE_PROGRAMME_TYPES_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
