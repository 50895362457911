const FETCH_COACHES_DOCS = 'FETCH_COACHES_DOCS';
const FETCH_COACHES_DOCS_SUCCESS = 'FETCH_COACHES_DOCS_SUCCESS';
const FETCH_COACHES_DOCS_ERROR = 'FETCH_COACHES_DOCS_ERROR';
const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
const FETCH_ACTIVITIES_ERROR = 'FETCH_ACTIVITIES_ERROR';
const FETCH_ACTIVITIES_GRAPH_DATA = 'FETCH_ACTIVITIES_GRAPH_DATA';
const FETCH_ACTIVITIES_GRAPH_DATA_SUCCESS =
  'FETCH_ACTIVITIES_GRAPH_DATA_SUCCESS';
const FETCH_ACTIVITIES_GRAPH_DATA_ERROR = 'FETCH_ACTIVITIES_GRAPH_DATA_ERROR';
const FETCH_ACTIVITIES_SEARCH_DATA = 'FETCH_ACTIVITIES_SEARCH_DATA';
const FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS =
  'FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS';
const FETCH_ACTIVITIES_SEARCH_DATA_ERROR = 'FETCH_ACTIVITIES_SEARCH_DATA_ERROR';
const FETCH_BATCH_UPDATE = 'FETCH_BATCH_UPDATE';
const FETCH_BATCH_UPDATE_SUCCESS = 'FETCH_BATCH_UPDATE_SUCCESS';
const FETCH_BATCH_UPDATE_ERROR = 'FETCH_BATCH_UPDATE_ERROR';

export {
  FETCH_COACHES_DOCS,
  FETCH_COACHES_DOCS_SUCCESS,
  FETCH_COACHES_DOCS_ERROR,
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_ACTIVITIES_ERROR,
  FETCH_ACTIVITIES_GRAPH_DATA,
  FETCH_ACTIVITIES_GRAPH_DATA_SUCCESS,
  FETCH_ACTIVITIES_GRAPH_DATA_ERROR,
  FETCH_ACTIVITIES_SEARCH_DATA,
  FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS,
  FETCH_ACTIVITIES_SEARCH_DATA_ERROR,
  FETCH_BATCH_UPDATE,
  FETCH_BATCH_UPDATE_SUCCESS,
  FETCH_BATCH_UPDATE_ERROR,
};
