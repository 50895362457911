import { colorsArray, sortDocumentSalesRep, sortDepartments } from "../utils";
import * as constants from "./constants";

const initialState = {
  allDocument: [],
  fetchingLoading: true,
  updateLoading: false,
  calculate_document_time: [],
  sales_user_document: [],
  total_hours: 0,
  totalRecords: 0,
  deleteAttachLoading: false,
  salesRepOptions: [],
  documentTypesOptions: [],
  fetchUtilsLoading: false,
  isAddDocument: false,
  isUpdateDocument: false,
  isCopyDocument: false,
  colorsObjArr: [],
  deletingLoading: false,
  graphData: {},
};

const documentControlReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_DOCUMENT_CONTROL:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_DOCUMENT_CONTROL_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allDocument: [action.data, ...state.allDocument],
        isAddDocument: false,
        isUpdateDocument: false,
        isCopyDocument: false,
      };
    case constants.CREATE_DOCUMENT_CONTROL_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAddDocument: false,
        isUpdateDocument: false,
        isCopyDocument: false,
      };
    case constants.FETCH_DOCUMENT_CONTROL_SEARCH_DATA:
      return {
        ...state,
        fetchUtilsLoading: true,
      };
    case constants.FETCH_DOCUMENT_CONTROL_SEARCH_DATA_SUCCESS:
      const { document_types, sales_reps } = action.data;

      const sortedDocumentTypes = sortDepartments(document_types);
      const sortedSalesRep = sortDocumentSalesRep(sales_reps);

      let documentTypesOptions = sortedDocumentTypes.map((el) => {
        const { document_type, id } = el;
        return { value: id, label: document_type };
      });

      let salesRepOptions = sortedSalesRep.map((el) => {
        const { username, id } = el;
        return { value: id, label: username };
      });

      return {
        ...state,
        fetchUtilsLoading: false,
        salesRepOptions,
        documentTypesOptions,
      };
    case constants.FETCH_DOCUMENT_CONTROL_SEARCH_DATA_ERROR:
      return {
        ...state,
        fetchUtilsLoading: false,
      };

    case constants.FETCH_DOCUMENT_CONTROL:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_DOCUMENT_CONTROL_SUCCESS:
      const graphDataArray = [];
      for (const [key, value] of Object.entries(action.data)) {
        graphDataArray.push({ ...value, type: key });
      }
      graphDataArray.splice(graphDataArray.length - 2, 2);
      return {
        ...state,
        fetchingLoading: false,
        graphData: graphDataArray,
        total: action.data.total,
        completed: action.data.completed,
      };
    case constants.FETCH_DOCUMENT_CONTROL_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };
    case constants.UPDATE_DOCUMENT_CONTROL:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_DOCUMENT_CONTROL_SUCCESS:
      const updated = state.allDocument.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allDocument: updated,
        updateLoading: false,
        isAddDocument: false,
        isUpdateDocument: false,
        isCopyDocument: false,
      };
    case constants.UPDATE_DOCUMENT_CONTROL_ERROR:
      return {
        ...state,
        updateLoading: false,
      };
    case constants.DELETE_DOCUMENT_CONTROL:
      return {
        ...state,
        deletingLoading: true,
      };
    case constants.DELETE_DOCUMENT_CONTROL_SUCCESS:
      const updatedAct = state.allDocument.filter(
        (el) => el.id !== action.data.id
      );
      return {
        ...state,
        allDocument: updatedAct,
        deletingLoading: false,
      };
    case constants.DELETE_DOCUMENT_CONTROL_ERROR:
      return {
        ...state,
        deletingLoading: false,
      };
    case constants.DELETE_DOCUMENT_CONTROL_ATTACH:
      return {
        ...state,
        deleteAttachLoading: true,
      };
    case constants.DELETE_DOCUMENT_CONTROL_ATTACH_SUCCESS:
      const {
        recordId,
        imgItem: { id },
      } = action.data;

      let updatedDocument = [];

      state.allDocument.map((el) => {
        if (el.id === recordId) {
          let fetch_images = el.fetch_images.filter((img) => img.key !== id);
          updatedDocument.push({ ...el, fetch_images });
        } else {
          updatedDocument.push(el);
        }
      });

      return {
        ...state,
        allDocument: updatedDocument,
        deleteAttachLoading: false,
      };
    case constants.UPDATE_ISADD_ISUPDATE:
      const { isAddDocument, isUpdateDocument, isCopyDocument } = action.data;
      return {
        ...state,
        isAddDocument,
        isUpdateDocument,
        isCopyDocument,
      };
    case constants.DELETE_DOCUMENT_CONTROL_ATTACH_ERROR:
      return {
        ...state,
        deleteAttachLoading: false,
      };
    default:
      return state;
  }
};

export default documentControlReducer;
