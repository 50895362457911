import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button, Select, message } from 'antd';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
// import { saveDepartment, setDepartDisableIndex } from '../redux/actions';
import { setEnrollmentsIsEdit, updateSlotEnrollments } from '../redux/actions';
import SpinnerComponent from '../../../components/spinner';

const StudentBookingSection = ({ canEditEveryThing, usersData }) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [render, setRender] = useState(true);

  const { slotBookingsLoading, currentNpoObj, slot_bookings, bookingIsEdit } =
    useSelector((state) => state.slotsReducer);

  const handleIndex = (index) => {};

  useEffect(() => {
    for (let i = 0; i < slot_bookings.length; i++) {
      const { curriculum_student, status, _id } = slot_bookings[i];
      const { curriculum, student } = curriculum_student;
      form.setFields([
        {
          name: ['enrollments', i, 'curriculum_id'],
          value: curriculum._id,
        },
        {
          name: ['enrollments', i, 'student_id'],
          value: student._id,
        },
        {
          name: ['enrollments', i, 'status'],
          value: status,
        },
        {
          name: ['enrollments', i, 'isAdd'],
          value: false,
        },
        {
          name: ['enrollments', i, 'slot_booking_id'],
          value: _id,
        },
        {
          name: ['enrollments', i, 'curriculum_student_id'],
          value: curriculum_student._id,
        },
      ]);
    }
    // if (
    //   utilsData?.curriculum_ids &&
    //   currentUserObj?.departments_with_steps?.length > 0
    // ) {
    //   setallDeparts(currentUserObj?.departments_with_steps);
    //   form.setFieldsValue(currentUserObj.departments_with_steps || []);

    //   currentUserObj?.departments_with_steps?.map((el, index) => {
    //     const { email } = el;
    //     return form.setFields([
    //       {
    //         name: ['enrollments', index, 'email'],
    //         value: email,
    //       },
    //       {
    //         name: ['enrollments', index, 'curriculum_id'],
    //         // value: hanldePosition(el.curriculum_id),
    //         value: el.curriculum_id,
    //       },
    //       {
    //         name: ['enrollments', index, 'student_id'],
    //         value: el?.processing_step?.name,
    //         // value: el?.processing_steps
    //       },
    //       // {
    //       //   name: ["enrollments", index, "id"],
    //       //   value: index,
    //       // },
    //     ]);
    //   });
    //   // setCurrentItemsIndex(currentUserObj?.departments_with_steps?.length - 1)
    //   // dispatch(
    //   //   setDepartDisableIndex(
    //   //     currentUserObj?.departments_with_steps?.length - 1
    //   //   )
    //   // );
    // } else {
    //   // setallDeparts(currentUserObj?.departments_with_steps);
    //   // form.resetFields();
    // }
  }, [slot_bookings]);

  const onFinish = (values) => {
    const { enrollments } = values;
    let emailsArr = [];
    // enrollments.forEach((el) => emailsArr.push(el.email));
    const toFindDuplicates = (arry) =>
      arry.filter((item, index) => arry.indexOf(item) !== index);
    const duplicateElements = toFindDuplicates(emailsArr);
    if (duplicateElements.length > 0) {
      return message.error('Departments should not be duplicate');
    }

    let updatedData = enrollments.map((el) => {
      return {
        ...el,
        action: el?.isAdd === false ? 'update' : 'add',
      };
    });

    dispatch(
      updateSlotEnrollments({
        slot_bookings: updatedData,
        slot_id: currentNpoObj._id,
      })
    );
  };

  function onChange(index, changedKey, curriculum_student_id) {
    if (changedKey === 'student') {
      form.setFieldValue(['enrollments', index, 'curriculum_id'], undefined);
    }
    if (changedKey === 'curriculum') {
      form.setFieldValue(
        ['enrollments', index, 'curriculum_student_id'],
        curriculum_student_id
      );
    }
    setRender(!render);
  }

  function onSearch(val) {}

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
    scrollToFirstError: true,
  };

  const isAlreadyEnrolled = (curriculum_id, student_id) => {
    let isEnrolled = false;
    let enrollments = form.getFieldsValue().enrollments;
    enrollments.forEach((enrollment) => {
      if (enrollment?.student_id === student_id) {
        if (enrollment?.curriculum_id === curriculum_id) {
          isEnrolled = true;
        }
      }
    });
    return isEnrolled;
  };

  const getCurriculumOptions = (index) => {
    let options = [];
    let enrollments = form.getFieldsValue().enrollments;
    let student_id = enrollments[index]?.student_id;

    let selectCurriculumIds = [];
    if (student_id?.length > 0) {
      const filteredUser = usersData.filter((el) => el._id === student_id);
      let curriculums = filteredUser[0]?.curriculum_students || [];
      // Find corresponding curriculum_students entries and disable them
      enrollments.forEach((enrollment) => {
        options = curriculums.map((curriculum) => {
          let firstMatchFound = isAlreadyEnrolled(
            curriculum?.curriculum?._id,
            student_id
          );
          let isSelected =
            curriculum?.curriculum._id === enrollment?.curriculum_id;
          selectCurriculumIds.push(curriculum?.curriculum._id);

          curriculum.disabled = firstMatchFound && !isSelected;
          if (firstMatchFound) {
            firstMatchFound = true;
          }

          return curriculum;
        });
      });
    }
    return options;
  };

  const getStatuses = (index) => {
    let options = [{ label: 'Pending', value: 'pending' }];
    let enrollment = form.getFieldsValue().enrollments[index];

    if (enrollment?.status?.length > 0) {
      options = [
        { label: 'Pending', value: 'pending' },
        { label: 'Cancel', value: 'cancel' },
        { label: 'Completed', value: 'completed' },
      ];
    }

    return options;
  };

  const showDeleteIcon = (index) => {
    let show = true;
    let item = form.getFieldsValue().enrollments[index];
    if (item?.isAdd === false) {
      show = false;
    }
    return show;
  };

  let capacity = currentNpoObj.capacity;

  const canAddMore = () => {
    let enrollments = form.getFieldsValue()?.enrollments || [];

    return enrollments.length < capacity;
  };

  return (
    <>
      <Row>
        <Col span={24} className='heading-col'>
          <Row align='middle' justify='space-between'>
            <Col>
              <div>
                <span className='heading-span'>Enroll Students</span>
              </div>
            </Col>
            <Col>
              {bookingIsEdit ? (
                slotBookingsLoading ? (
                  <SpinnerComponent fontSize={14} />
                ) : (
                  <SaveIcon
                    width={16}
                    height={16}
                    fill='#e21c21'
                    onClick={() => {
                      form.submit();
                    }}
                  />
                )
              ) : (
                canEditEveryThing && (
                  <EditIcon
                    width={16}
                    fill='#e21c21'
                    height={16}
                    onClick={() => dispatch(setEnrollmentsIsEdit(true))}
                  />
                )
              )}
            </Col>
          </Row>
        </Col>
        <Col xxl={2}></Col>
        <Col md={24} lg={24} xl={24} xxl={22} style={{ minHeight: '20vh' }}>
          <Form
            name='enrollments-form'
            onFinish={onFinish}
            autoComplete='off'
            form={form}
            {...formItemLayout}
            layout='vertical'
            requiredMark={false}
          >
            <Form.List name='enrollments'>
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Row gutter={[30, 0]} align='middle' key={key}>
                        <Col md={11} lg={11} xl={11} xxl={11}>
                          <Form.Item
                            {...restField}
                            name={[name, 'student_id']}
                            rules={[
                              { required: true, message: 'Missing Student' },
                            ]}
                            label={`Student`}
                            style={{
                              marginBottom: '12px',
                            }}
                          >
                            <Select
                              disabled={!bookingIsEdit}
                              style={{
                                width: '100%',
                              }}
                              getPopupContainer={() =>
                                document.getElementsByClassName(
                                  'ant-layout-content layout-content site-layout-background custom-textarea'
                                )[0]
                              }
                              className='department-select'
                              dropdownClassName='select-dropdown-custom'
                              showSearch
                              placeholder='Select student'
                              optionFilterProp='children'
                              onChange={() => {
                                onChange(index, 'student');
                              }}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {usersData?.map(({ email, user_id, _id }) => (
                                <Option key={_id} value={_id}>
                                  {`${email} - ${user_id}`}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={7} lg={7} xl={7} xxl={7}>
                          <Form.Item
                            {...restField}
                            name={[name, 'curriculum_id']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing Curriculum',
                              },
                            ]}
                            label='Curriculum'
                            style={{
                              marginBottom: '12px',
                            }}
                          >
                            <Select
                              disabled={!bookingIsEdit}
                              showSearch
                              dropdownClassName='select-dropdown-custom'
                              getPopupContainer={() =>
                                document.getElementsByClassName(
                                  'ant-layout-content layout-content site-layout-background custom-textarea'
                                )[0]
                              }
                              placeholder='Select curriculum'
                              optionFilterProp='children'
                              onChange={(e, { curriculum_student_id }) => {
                                onChange(
                                  index,
                                  'curriculum',
                                  curriculum_student_id
                                );
                              }}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {getCurriculumOptions(index)?.map(
                                ({ curriculum, disabled, _id }) => {
                                  if (curriculum) {
                                    return (
                                      <Option
                                        key={curriculum?._id}
                                        value={curriculum?._id}
                                        disabled={disabled}
                                        curriculum_student_id={_id}
                                      >
                                        {curriculum?.name}
                                      </Option>
                                    );
                                  }
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={4} lg={4} xl={4} xxl={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'status']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing Status',
                              },
                            ]}
                            label='Status'
                            style={{
                              marginBottom: '12px',
                            }}
                          >
                            <Select
                              disabled={!bookingIsEdit}
                              showSearch
                              dropdownClassName='select-dropdown-custom'
                              getPopupContainer={() =>
                                document.getElementsByClassName(
                                  'ant-layout-content layout-content site-layout-background custom-textarea'
                                )[0]
                              }
                              placeholder='Status'
                              optionFilterProp='children'
                              onChange={() => onChange(index)}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {getStatuses(index)?.map(({ value, label }) => (
                                <Option key={value} value={value}>
                                  {label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        {bookingIsEdit && showDeleteIcon(index) && (
                          <Col span={2}>
                            <DeleteIcon
                              height={17}
                              width={17}
                              fill='#e21c21'
                              style={{
                                cursor: 'pointer',
                                marginTop: '20px',
                              }}
                              onClick={() => {
                                handleIndex(index);
                                remove(name);
                              }}
                            />
                          </Col>
                        )}
                      </Row>
                    ))}
                    {bookingIsEdit && canAddMore() && (
                      <Button
                        className='add-department-btn'
                        style={{
                          marginBottom: '30px',
                        }}
                        onClick={() => {
                          add();
                        }}
                        disabled={!bookingIsEdit}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Student
                      </Button>
                    )}
                  </>
                );
              }}
            </Form.List>
          </Form>
        </Col>
      </Row>
      <div className='empty-div'></div>
    </>
  );
};

export default StudentBookingSection;
