import * as constants from "./constants";

const initialState = {
  allProjectDistributions: [],
  fetchingLoading: false,
  updateLoading: false,
  deleteAttachLoading: false,
  fetchUtilsLoading: false,
  isAddProjectDistribution: false,
  isUpdateProjectDistribution: false,
  deletingLoading: false,
};

const projectDistributionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECT_DISTRIBUTION:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_PROJECT_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allProjectDistributions: action.data,
      };
    case constants.FETCH_PROJECT_DISTRIBUTION_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.CREATE_PROJECT_DISTRIBUTION:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_PROJECT_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allProjectDistributions: [
          action.data,
          ...state.allProjectDistributions,
        ],
        isAddProjectDistribution: false,
        isUpdateProjectDistribution: false,
      };
    case constants.CREATE_PROJECT_DISTRIBUTION_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAddProjectDistribution: false,
        isUpdateProjectDistribution: false,
      };

    case constants.UPDATE_PROJECT_DISTRIBUTION:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_PROJECT_DISTRIBUTION_SUCCESS:
      const updated = state.allProjectDistributions.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allProjectDistributions: updated,
        updateLoading: false,
        isAddProjectDistribution: false,
        isUpdateProjectDistribution: false,
      };
    case constants.UPDATE_PROJECT_DISTRIBUTION_ERROR:
      return {
        ...state,
        updateLoading: false,
      };
    case constants.DELETE_PROJECT_DISTRIBUTION:
      return {
        ...state,
        deletingLoading: true,
      };
    case constants.DELETE_PROJECT_DISTRIBUTION_SUCCESS:
      const updatedAct = state.allProjectDistributions.filter(
        (el) => el.id !== action.data.id
      );
      return {
        ...state,
        allProjectDistributions: updatedAct,
        deletingLoading: false,
      };
    case constants.DELETE_PROJECT_DISTRIBUTION_ERROR:
      return {
        ...state,
        deletingLoading: false,
      };
    case constants.DELETE_PROJECT_DISTRIBUTION_ATTACH:
      return {
        ...state,
        deleteAttachLoading: true,
      };
    case constants.DELETE_PROJECT_DISTRIBUTION_ATTACH_SUCCESS:
      const {
        recordId,
        imgItem: { id },
      } = action.data;

      let updatedActivities = [];

      state.allProjectDistributions.map((el) => {
        if (el.id === recordId) {
          let distribution_attachments = el.distribution_attachments.filter(
            (img) => img.id != id
          );
          updatedActivities.push({ ...el, distribution_attachments });
        } else {
          updatedActivities.push(el);
        }
      });

      return {
        ...state,
        allProjectDistributions: updatedActivities,
        deleteAttachLoading: false,
      };
    case constants.UPDATE_PROJECT_DISTRIBUTION_ISADD_ISUPDATE:
      const { isAddProjectDistribution, isUpdateProjectDistribution } =
        action.data;
      return {
        ...state,
        isAddProjectDistribution,
        isUpdateProjectDistribution,
      };
    case constants.DELETE_PROJECT_DISTRIBUTION_ATTACH_ERROR:
      return {
        ...state,
        deleteAttachLoading: false,
      };
    default:
      return state;
  }
};

export default projectDistributionReducer;
