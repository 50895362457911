import React, { useEffect, useState, useRef } from 'react';
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Space,
  Col,
  Row,
  message,
  Select,
} from 'antd';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../assets/save-icon.svg';
import { ReactComponent as CrossIcon } from '../../assets/cross-icon.svg';
import sampleImg from '../../assets/user-icon.svg';

import SpinnerComponent from '../../components/spinner';
import { arrayMoveImmutable } from 'array-move';
// import DragDropIcon from '../../assets/dragdropicon.png';
import { baseURL } from '../../globalUtils/axiosConfig';
import {
  createSiteStatuses,
  deleteSiteStatuses,
  fetchSiteStatuses,
  isAddingAction,
  updateSiteOrder,
  updateSiteStatuses,
  setStorage,
} from './redux/actions';
import {
  SortableContainer,
  SortableElement,
  // SortableHandle,
} from 'react-sortable-hoc';

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);
// const DragHandle = SortableHandle(() => <img alt='' src={DragDropIcon} />);
const { Option } = Select;

const StickersTable = ({ canUpdate, canDelete, id }) => {
  const [editingLoading, setEditingLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [data, setData] = useState([]);
  const [icon, setIcon] = useState(null);
  const [isIconSelected, setIsIconSelected] = useState(false);
  const dispatch = useDispatch();
  const [search, setSearch] = useState({});
  const [req, setReq] = useState(false);

  const [form] = Form.useForm();
  const ref = useRef();
  const isEditing = (record) => record._id === editingKey;

  const { allSiteStatuses, fetchingLoading, updateLoading, isAdding, ...rest } =
    useSelector((state) => state.stickersReducer);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 500)
  );

  useEffect(() => {
    if (localStorage.getItem('stickerManagerState')) {
      const localStorageData = JSON.parse(
        localStorage.getItem('stickerManagerState')
      );

      if (localStorageData) {
        setTimeout(() => {
          setSearch(localStorageData.search);
          dispatch(setStorage(localStorageData));

          debouncedCallApi();
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    dispatch(fetchSiteStatuses(id));

    return () => {
      dispatch(isAddingAction(false));
    };
  }, []);

  useEffect(() => {
    setEditingLoading(updateLoading);
    if (!updateLoading) {
      setEditingKey('');
    }
  }, [updateLoading]);

  useEffect(() => {
    setData(allSiteStatuses);
  }, [allSiteStatuses]);

  useEffect(() => {
    let filteredData = allSiteStatuses;

    if (search?.name?.length > 0) {
      filteredData = filteredData.filter((el) =>
        el.name?.toLowerCase()?.includes(search.name?.toLowerCase())
      );
    }

    if (search?.deleted_status?.length > 0) {
      filteredData = filteredData.filter(
        (el) => el.deleted_status === search.deleted_status
      );
    }

    setData(filteredData);
  }, [req]);

  useEffect(() => {
    const isSearchChanged =
      JSON.stringify(search) !== JSON.stringify(rest.search);

    if (isSearchChanged) {
      setSearch(rest.search);
    }

    if (isSearchChanged) {
      debouncedCallApi();
    }
  }, []);

  const changeHandler = (event) => {
    const image = event.target.files[0];
    if (!image) {
      return false;
    }
    if (!image.name.match(/\.(svg)$/)) {
      message.error('Photo should be svg format.');
      return false;
    }
    if (image.size > 5120000) {
      message.error('Photo size should be less than 5MB.');
      return false;
    }
    setIcon(event.target.files[0]);
    setIsIconSelected(true);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const textInput = (
      <Input
        placeholder='Insert Sticker Name'
        ref={ref}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            save(record._id, id);
          }
        }}
      />
    );

    let inputNode = null;

    if (dataIndex === 'icon') {
      inputNode = (
        <div style={{ width: '40px' }}>
          <div
            style={{
              width: '30px',
              height: '30px',
              textAlign: 'center',
            }}
          >
            <img
              width='100%'
              height='100%'
              style={{
                objectFit: 'contain',
              }}
              src={
                isIconSelected
                  ? URL.createObjectURL(icon)
                  : icon
                  ? `${baseURL}/${icon}`
                  : sampleImg
              }
              alt=''
            />
          </div>
          <div
            className='edit-icon-div'
            style={
              {
                // textAlign: 'center',
              }
            }
          >
            <input
              // readOnly={loading}
              // disabled={!isEdit}
              type='file'
              name='profileImg'
              id='profileImg'
              accept='image/*'
              style={{ display: 'none' }}
              onChange={changeHandler}
            />
            <label
              htmlFor='profileImg'
              style={{
                paddingLeft: '10px',
              }}
            >
              <EditIcon height={14} width={14} fill='#50B6F2' />
            </label>
          </div>
        </div>
      );
    }

    if (dataIndex === 'name') {
      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `This field is required!`,
                },
                {
                  min: 1,
                  message: `invalid!`,
                },
              ]}
            >
              {textInput}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      );
    }

    if (dataIndex === 'deleted_status') {
      inputNode = (
        <Select
          showSearch
          className={`deleted-status-selector`}
          style={{ width: '100px', height: '30px' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          // onChange={handleUpdateSite}
          placeholder='Select Status'
          optionLabelProp='label'
        >
          {[
            { label: 'ACTIVE', value: 'active' },
            { label: 'INACTIVE', value: 'inactive' },
          ]?.map(({ label, value }) => {
            return (
              <Option value={value} key={value} label={label}>
                {label || ''}
              </Option>
            );
          })}
        </Select>
      );
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            valuePropName={'value'}
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Required!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(isAddingAction(true));
    if (record?.isAdd) {
    } else {
      setIcon(record.icon || null);
    }
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record._id);
    setTimeout(() => {
      ref.current?.focus();
    }, 300);
    setIsIconSelected(false);
  };

  const cancel = (record) => {
    dispatch(isAddingAction(false));
    form.resetFields();
    setEditingKey('');
    if (record?.isAdd) {
      return setData((data) => data.filter((el) => el._id !== record._id));
    } else {
      setData(data);
    }
  };

  const save = async (id, learning_activity_id) => {
    if (!icon) {
      return message.error('Sticker icon is required!');
    }
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item._id);

      if (index > -1) {
        const item = newData[index];
        if (item?.isAdd) {
          form.submit();
        } else {
          if (isAlreadyExist({ ...item, ...row })) {
            return message.error('Sticker name already exist!');
          }

          const { name, deleted_status } = row;

          let obj = { name: name.trim(), deleted_status };
          obj.learning_activity_id = learning_activity_id;
          if (isIconSelected) {
            obj.icon = icon;
          }
          obj.id = editingKey;

          dispatch(
            updateSiteStatuses({
              ...obj,
            })
          );
        }
      }
    } catch (errInfo) {
      if (errInfo?.values?.name === '') {
        message.error('Name should not be blank!');
      }
    }
  };

  const handleDelete = (record) => {
    setEditingKey(record._id);
    dispatch(deleteSiteStatuses(record));
  };

  const handleAdd = () => {
    dispatch(isAddingAction(true));
    let _id = Math.floor(Math.random() * 1122123456);
    const newData = {
      _id,
      icon: null,
      name: '',
      deleted_status: 'active',
      isAdd: true,
    };
    setData((data) => [newData, ...data]);
    setEditingKey(_id);
    setIcon(null);
    edit(newData);
  };

  const isAlreadyExist = (record) => {
    const { name } = record;
    const alreadyExist = data.filter(
      (el) => el.name?.trim()?.toLowerCase() === name?.trim()?.toLowerCase()
    );

    return alreadyExist.length > 0 && record._id !== alreadyExist[0]?._id
      ? true
      : false;
  };

  const handleFinish = (values) => {
    const { name, deleted_status } = values;

    if (isAlreadyExist(values)) {
      return message.error('Status name already exist!');
    }

    dispatch(
      createSiteStatuses({
        name: name.trim(),
        id: editingKey,
        icon,
        deleted_status,
        learning_activity_id: id,
      })
    );
  };

  const handleSetStorage = (key, value) => {
    dispatch(setStorage({ [key]: value }));
  };

  const handleSearch = (key, value, func = null) => {
    let updatedState = {
      ...search,
      [key]: value,
    };

    setSearch(updatedState);

    if (typeof func === 'function') {
      func();
    }

    dispatch(setStorage({ search: updatedState }));
    handleSetStorage('search', updatedState);
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      value={search[key]}
      onChange={(e) => {
        handleSearch(key, e.target.value, debouncedCallApi);
      }}
    />
  );

  const columns = [
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Icon</div>
            <div style={{ height: '25px' }}></div>
          </div>
        );
      },
      dataIndex: 'icon',
      key: 'icon',
      width: '20%',
      editable: true,
      align: 'left',
      render: (icon, record) => {
        if (!icon) {
          return;
        }

        return (
          <div
            style={{
              width: '30px',
              height: '30px',
              textAlign: 'center',
            }}
          >
            <img
              width='100%'
              height='100%'
              style={{
                objectFit: 'contain',
              }}
              src={`${baseURL}/${icon}`}
              alt='icon'
            />
          </div>
        );
      },
    },
    {
      // title: 'Sticker Name',
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Sticker Name</div>
            <div>{searchInput('name')}</div>
          </div>
        );
      },
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      editable: true,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Status</div>
            <div key={'1'}>
              <Select
                style={{
                  width: '100%',
                  textTransform: 'capitalize',
                }}
                size='small'
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                className='Select Gender'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                showSearch
                placeholder=''
                value={search?.deleted_status}
                optionFilterProp='children'
                onChange={(e) =>
                  handleSearch('deleted_status', e, debouncedCallApi)
                }
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {[
                  { label: 'ACTIVE', value: 'active' },
                  { label: 'INACTIVE', value: 'inactive' },
                ]?.map(({ label, value }) => {
                  return (
                    <Option value={value} label={label}>
                      {label || ''}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'deleted_status',
      key: 'deleted_status',
      width: '20%',
      editable: true,
      align: 'center',
      sorter: (a, b) => a.deleted_status?.localeCompare(b.deleted_status),
      render: (deleted_status) => {
        return (
          <div style={{ paddingLeft: '12px' }}>
            {deleted_status?.toUpperCase()}
          </div>
        );
      },
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: '',
      width: '20%',
      align: 'center',
      render: (_, record) => {
        if (!canUpdate && !canDelete) {
          return;
        }

        const editable = isEditing(record);
        return editable ? (
          editingLoading && editingKey === record._id ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <Space size={20} align='center'>
              <SaveIcon
                className='custom-icon'
                onClick={() => save(record._id, id)}
              />
              <Popconfirm
                title='Sure to cancel?'
                onConfirm={() => cancel(record)}
              >
                <CrossIcon
                  style={{ height: '20px', width: '20px' }}
                  className='custom-icon'
                />
              </Popconfirm>
            </Space>
          )
        ) : (
          <Space size={20} align='center'>
            {canUpdate && (
              <EditIcon
                className={`custom-icon ${isAdding ? 'disable-icon' : ''}`}
                disabled={editingKey !== ''}
                onClick={() => !isAdding && edit(record)}
              />
            )}
            {/* {canDelete &&
              data.length >= 1 &&
              (isAdding ? (
                <DeleteIcon className={`custom-icon disable-icon`} />
              ) : (
                <Popconfirm
                  title='Are you sure you want to delete this status?'
                  onConfirm={() => handleDelete(record)}
                  okText='Yes'
                  cancelText='No'
                >
                  <DeleteIcon className='custom-icon' />
                </Popconfirm>
              ))} */}
          </Space>
        );
      },
    },
    // {
    //   title: 'Order',
    //   dataIndex: 'order',
    //   key: 'order',
    //   width: '20%',
    //   className: 'drag-visible',
    //   align: 'center',
    //   render: (order, record) => {
    //     return !Boolean(editingKey) && canUpdate && <DragHandle />;
    //   },
    // },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        data.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);

      dispatch(updateSiteOrder(newData));
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex(
      (x) => x.position == restProps['data-row-key']
    );

    return <SortableItem index={index} {...restProps} />;
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Row justify='center' className='production-manager-parent-row'>
      <Col span={24} className='heading-col'>
        <Row align='middle' gutter={[50, 0]} justify='space-between'>
          <Col>
            <Row gutter={[50, 0]} align='middle'>
              <Col>
                <div>
                  <span className='heading-span'>Stickers Register</span>
                </div>
              </Col>
              {canUpdate && (
                <Col>
                  <Button
                    icon={<PlusOutlined />}
                    className='add-doc-btn class-btn'
                    size='middle'
                    onClick={handleAdd}
                    disabled={isAdding}
                    // disabled={shouldAddBtnDisable()}
                  >
                    Sticker
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  onClick={() => {
                    handleSetStorage('search', {});
                    setSearch({});
                    debouncedCallApi();
                  }}
                  icon={<CloseOutlined />}
                  className='add-user-btn'
                  size='middle'
                >
                  Clear Filters
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col md={24} lg={23} xl={20} xxl={20} style={{ minHeight: '60vh' }}>
        <Form
          form={form}
          component={false}
          onFinish={handleFinish}
          className='table-style'
        >
          <Table
            className='special-table activity-type-table project-statuses-table'
            style={{
              marginTop: '30px',
            }}
            components={{
              body: {
                cell: EditableCell,
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            scroll={{ x: !fetchingLoading }}
            rowClassName='editable-row'
            dataSource={data}
            loading={fetchingLoading}
            columns={mergedColumns}
            pagination={false}
            rowKey='position'
          />
        </Form>
      </Col>
    </Row>
  );
};

export default StickersTable;
