const FETCH_LEARNER_CAMPS = 'FETCH_LEARNER_CAMPS';
const FETCH_LEARNER_CAMPS_SUCCESS = 'FETCH_LEARNER_CAMPS_SUCCESS';
const FETCH_LEARNER_CAMPS_ERROR = 'FETCH_LEARNER_CAMPS_ERROR';
const UPDATE_LEARNER_CAMPS = 'UPDATE_LEARNER_CAMPS';
const UPDATE_LEARNER_CAMPS_SUCCESS = 'UPDATE_LEARNER_CAMPS_SUCCESS';
const UPDATE_LEARNER_CAMPS_ERROR = 'UPDATE_LEARNER_CAMPS_ERROR';
const DELETE_LEARNER_CAMPS = 'DELETE_LEARNER_CAMPS';
const DELETE_LEARNER_CAMPS_SUCCESS = 'DELETE_LEARNER_CAMPS_SUCCESS';
const DELETE_LEARNER_CAMPS_ERROR = 'DELETE_LEARNER_CAMPS_ERROR';
const CREATE_LEARNER_CAMPS = 'CREATE_LEARNER_CAMPS';
const CREATE_LEARNER_CAMPS_SUCCESS = 'CREATE_LEARNER_CAMPS_SUCCESS';
const CREATE_LEARNER_CAMPS_ERROR = 'CREATE_LEARNER_CAMPS_ERROR';
const UPDATE_ORDER_LEARNER_CAMPS = 'UPDATE_ORDER_LEARNER_CAMPS';
const UPDATE_ORDER_LEARNER_CAMPS_SUCCESS = 'UPDATE_ORDER_LEARNER_CAMPS_SUCCESS';
const UPDATE_ORDER_LEARNER_CAMPS_ERROR = 'UPDATE_ORDER_LEARNER_CAMPS_ERROR';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';

export {
  FETCH_LEARNER_CAMPS,
  FETCH_LEARNER_CAMPS_SUCCESS,
  FETCH_LEARNER_CAMPS_ERROR,
  UPDATE_LEARNER_CAMPS,
  UPDATE_LEARNER_CAMPS_SUCCESS,
  UPDATE_LEARNER_CAMPS_ERROR,
  DELETE_LEARNER_CAMPS,
  DELETE_LEARNER_CAMPS_SUCCESS,
  DELETE_LEARNER_CAMPS_ERROR,
  CREATE_LEARNER_CAMPS,
  CREATE_LEARNER_CAMPS_SUCCESS,
  CREATE_LEARNER_CAMPS_ERROR,
  UPDATE_ORDER_LEARNER_CAMPS,
  UPDATE_ORDER_LEARNER_CAMPS_SUCCESS,
  UPDATE_ORDER_LEARNER_CAMPS_ERROR,
  IS_ADDING_UPDATE,
};
