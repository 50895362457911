import * as constants from './constants';

export const fetchNpoRegions = (data) => {
  return {
    type: constants.FETCH_NPO_REGIONS,
    data,
  };
};

export const fetchNpoRegionsSuccess = (data) => {
  return {
    type: constants.FETCH_NPO_REGIONS_SUCCESS,
    data: data,
  };
};

export const fetchNpoRegionsError = (data) => {
  return {
    type: constants.FETCH_NPO_REGIONS_ERROR,
    data: data,
  };
};

export const updateNpoRegions = (data) => {
  return {
    type: constants.UPDATE_NPO_REGIONS,
    data: data,
  };
};

export const updateNpoRegionsSuccess = (data) => {
  return {
    type: constants.UPDATE_NPO_REGIONS_SUCCESS,
    data: data,
  };
};

export const updateNpoRegionsError = () => {
  return {
    type: constants.UPDATE_NPO_REGIONS_ERROR,
  };
};

export const updateNpoRegionsOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_NPO_REGIONS,
    data: data,
  };
};

export const updateNpoRegionsOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_NPO_REGIONS_SUCCESS,
    data: data,
  };
};

export const updateNpoRegionsOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_NPO_REGIONS_ERROR,
  };
};

export const deleteNpoRegions = (data) => {
  return {
    type: constants.DELETE_NPO_REGIONS,
    data: data,
  };
};

export const deleteNpoRegionsSuccess = (data) => {
  return {
    type: constants.DELETE_NPO_REGIONS_SUCCESS,
    data: data,
  };
};

export const deleteNpoRegionsError = () => {
  return {
    type: constants.DELETE_NPO_REGIONS_ERROR,
  };
};

export const createNpoRegions = (data, id) => {
  return {
    type: constants.CREATE_NPO_REGIONS,
    data: data,
  };
};

export const createNpoRegionsSuccess = (data) => {
  return {
    type: constants.CREATE_NPO_REGIONS_SUCCESS,
    data: data,
  };
};

export const createNpoRegionsError = (id) => {
  return {
    type: constants.CREATE_NPO_REGIONS_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
