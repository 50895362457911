const FETCH_COACH_TYPES = 'FETCH_COACH_TYPES';
const FETCH_COACH_TYPES_SUCCESS = 'FETCH_COACH_TYPES_SUCCESS';
const FETCH_COACH_TYPES_ERROR = 'FETCH_COACH_TYPES_ERROR';
const UPDATE_COACH_TYPES = 'UPDATE_COACH_TYPES';
const UPDATE_COACH_TYPES_SUCCESS = 'UPDATE_COACH_TYPES_SUCCESS';
const UPDATE_COACH_TYPES_ERROR = 'UPDATE_COACH_TYPES_ERROR';
const DELETE_COACH_TYPES = 'DELETE_COACH_TYPES';
const DELETE_COACH_TYPES_SUCCESS = 'DELETE_COACH_TYPES_SUCCESS';
const DELETE_COACH_TYPES_ERROR = 'DELETE_COACH_TYPES_ERROR';
const CREATE_COACH_TYPES = 'CREATE_COACH_TYPES';
const CREATE_COACH_TYPES_SUCCESS = 'CREATE_COACH_TYPES_SUCCESS';
const CREATE_COACH_TYPES_ERROR = 'CREATE_COACH_TYPES_ERROR';
const UPDATE_ORDER_COACH_TYPES = 'UPDATE_ORDER_COACH_TYPES';
const UPDATE_ORDER_COACH_TYPES_SUCCESS = 'UPDATE_ORDER_COACH_TYPES_SUCCESS';
const UPDATE_ORDER_COACH_TYPES_ERROR = 'UPDATE_ORDER_COACH_TYPES_ERROR';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';

export {
  FETCH_COACH_TYPES,
  FETCH_COACH_TYPES_SUCCESS,
  FETCH_COACH_TYPES_ERROR,
  UPDATE_COACH_TYPES,
  UPDATE_COACH_TYPES_SUCCESS,
  UPDATE_COACH_TYPES_ERROR,
  DELETE_COACH_TYPES,
  DELETE_COACH_TYPES_SUCCESS,
  DELETE_COACH_TYPES_ERROR,
  CREATE_COACH_TYPES,
  CREATE_COACH_TYPES_SUCCESS,
  CREATE_COACH_TYPES_ERROR,
  UPDATE_ORDER_COACH_TYPES,
  UPDATE_ORDER_COACH_TYPES_SUCCESS,
  UPDATE_ORDER_COACH_TYPES_ERROR,
  IS_ADDING_UPDATE,
};
