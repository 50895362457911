import * as constants from './constants';

export const fetchLearnerCamps = (data) => {
  return {
    type: constants.FETCH_LEARNER_CAMPS,
    data,
  };
};

export const fetchLearnerCampsSuccess = (data) => {
  return {
    type: constants.FETCH_LEARNER_CAMPS_SUCCESS,
    data: data,
  };
};

export const fetchLearnerCampsError = (data) => {
  return {
    type: constants.FETCH_LEARNER_CAMPS_ERROR,
    data: data,
  };
};

export const updateLearnerCamps = (data) => {
  return {
    type: constants.UPDATE_LEARNER_CAMPS,
    data: data,
  };
};

export const updateLearnerCampsSuccess = (data) => {
  return {
    type: constants.UPDATE_LEARNER_CAMPS_SUCCESS,
    data: data,
  };
};

export const updateLearnerCampsError = () => {
  return {
    type: constants.UPDATE_LEARNER_CAMPS_ERROR,
  };
};

export const updateLearnerCampsOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_LEARNER_CAMPS,
    data: data,
  };
};

export const updateLearnerCampsOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_LEARNER_CAMPS_SUCCESS,
    data: data,
  };
};

export const updateLearnerCampsOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_LEARNER_CAMPS_ERROR,
  };
};

export const deleteLearnerCamps = (data) => {
  return {
    type: constants.DELETE_LEARNER_CAMPS,
    data: data,
  };
};

export const deleteLearnerCampsSuccess = (data) => {
  return {
    type: constants.DELETE_LEARNER_CAMPS_SUCCESS,
    data: data,
  };
};

export const deleteLearnerCampsError = () => {
  return {
    type: constants.DELETE_LEARNER_CAMPS_ERROR,
  };
};

export const createLearnerCamps = (data, id) => {
  return {
    type: constants.CREATE_LEARNER_CAMPS,
    data: data,
  };
};

export const createLearnerCampsSuccess = (data) => {
  return {
    type: constants.CREATE_LEARNER_CAMPS_SUCCESS,
    data: data,
  };
};

export const createLearnerCampsError = (id) => {
  return {
    type: constants.CREATE_LEARNER_CAMPS_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
