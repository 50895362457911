import * as constants from "./constants";

const initialState = {
  allClients: [],
  createClientLoading: false,
  currentClientObj: {},
  clientDetailsIsEdit: false,
  fetchClientLoading: false,
  deleteClientLoading: false,
  fetchClientDetailLoading: false,
  utilsData: {},
  sendDetailLoading: false,
  settingsLoading: false,
  settingsUpdatingKey: {
    popup_notifications: false,
    email_notifications: false,
  },
  popupLoading: false,
  emailLoading: false,
  totalRecords: 0,
};

const clientManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_CLIENT_UTIL_DATA_SUCCESS:
      const { companies, contact_person, status } = action.data;
      const sortCompanies = companies.sort((a, b) =>
        (a.company || "").localeCompare(b.company || "")
      );
      const sortContactPerson = contact_person.sort((a, b) =>
        (a.listname || "").localeCompare(b.listname || "")
      );

      return {
        ...state,
        utilsData: {
          companies: sortCompanies,
          contact_person: sortContactPerson,
          status,
        },
      };
    case constants.CLIENT_DETAILS_IS_EDIT:
      return {
        ...state,
        clientDetailsIsEdit: action.data,
      };
    case constants.CREATE_CLIENT:
      return {
        ...state,
        createClientLoading: true,
      };
    case constants.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        createClientLoading: false,
        currentClientObj: action.data,
        clientDetailsIsEdit: false,
      };
    case constants.CREATE_CLIENT_ERROR:
      return {
        ...state,
        createClientLoading: false,
      };
    case constants.ADD_CLIENT_IN_MANAGER:
      return {
        ...state,
        createClientLoading: true,
      };
    case constants.FETCH_ALL_CLIENTS:
      return {
        ...state,
        fetchClientLoading: true,
      };
    case constants.FETCH_ALL_CLIENTS_SUCCESS:
      const { clients, total_clients } = action.data;
      return {
        ...state,
        allClients: clients,
        fetchClientLoading: false,
        totalRecords: total_clients,
      };
    case constants.FETCH_ALL_CLIENTS_ERROR:
      return {
        ...state,
        fetchClientLoading: false,
      };
    case constants.DELETE_CLIENT:
      return {
        ...state,
        deleteClientLoading: true,
      };
    case constants.DELETE_CLIENT_SUCCESS:
      let updatedUsers = state.allClients.filter(
        (el) => el.id !== action.data.id
      );
      return {
        ...state,
        deleteClientLoading: false,
        allClients: updatedUsers,
      };
    case constants.DELETE_CLIENT_ERROR:
      return {
        ...state,
        deleteClientLoading: false,
      };
    case constants.UPDATE_CLIENT:
      return {
        ...state,
        createClientLoading: true,
      };
    case constants.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        createClientLoading: false,
        currentClientObj: action.data,
        clientDetailsIsEdit: false,
      };
    case constants.UPDATE_CLIENT_ERROR:
      return {
        ...state,
        createClientLoading: false,
      };
    case constants.FETCH_CLIENT:
      return {
        ...state,
        fetchClientDetailLoading: true,
      };
    case constants.FETCH_CLIENT_SUCCESS:
      return {
        ...state,
        currentClientObj: action.data,
        fetchClientDetailLoading: false,
      };
    case constants.FETCH_CLIENT_ERROR:
      return {
        ...state,
        fetchClientDetailLoading: false,
      };
    case constants.SEND_DETAILS_TO_CLIENT:
      return {
        ...state,
        sendDetailLoading: true,
      };
    case constants.SEND_DETAILS_TO_CLIENT_SUCCESS:
      return {
        ...state,
        sendDetailLoading: false,
      };
    case constants.SEND_DETAILS_TO_CLIENT_ERROR:
      return {
        ...state,
        sendDetailLoading: false,
      };

    case constants.UPDATE_CLIENT_SETTINGS:
      const { data, loadingObj } = action.data;
      const { id, ...res } = data;
      // Object.keys(res)[0]
      return {
        ...state,
        settingsLoading: true,
        settingsUpdatingKey: { ...state.settingsUpdatingKey, ...res },
        ...loadingObj,
      };
    case constants.UPDATE_CLIENT_SETTINGS_SUCCESS:
      return {
        ...state,
        currentClientObj: { ...state.currentClientObj, ...action.data.data },
        settingsLoading: false,
        ...action.data.loadingObj,
      };
    case constants.UPDATE_CLIENT_SETTINGS_ERROR:
      // let obj = action.data;
      // let updated = { [Object.keys(obj)[0]]: !Object.values(obj)[0] };
      return {
        ...state,
        settingsLoading: false,
        currentClientObj: {
          ...state.currentClientObj,
        },
      };
    case constants.CLIENT_CLEANUP_FUNC:
      return {
        ...state,
        allClients: [],
        createClientLoading: false,
        currentClientObj: {},
        clientDetailsIsEdit: false,
        fetchClientLoading: false,
        deleteClientLoading: false,
        // utilsData: {},
        sendDetailLoading: false,
        settingsLoading: false,
        settingsUpdatingKey: {
          popup_notifications: false,
          email_notifications: false,
        },
      };
    default:
      return state;
  }
};

export default clientManagerReducer;
