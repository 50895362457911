import * as constants from "./constants";

const initialState = {
  allImsDistributions: [],
  fetchingLoading: false,
  updateLoading: false,
  deleteAttachLoading: false,
  fetchUtilsLoading: false,
  isAddImsDistribution: false,
  isUpdateImsDistribution: false,
  deletingLoading: false,
};

const imsDistributionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_IMS_DISTRIBUTION:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_IMS_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allImsDistributions: action.data,
      };
    case constants.FETCH_IMS_DISTRIBUTION_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.CREATE_IMS_DISTRIBUTION:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_IMS_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allImsDistributions: [action.data, ...state.allImsDistributions],
        isAddImsDistribution: false,
        isUpdateImsDistribution: false,
      };
    case constants.CREATE_IMS_DISTRIBUTION_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAddImsDistribution: false,
        isUpdateImsDistribution: false,
      };

    case constants.UPDATE_IMS_DISTRIBUTION:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_IMS_DISTRIBUTION_SUCCESS:
      const updated = state.allImsDistributions.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allImsDistributions: updated,
        updateLoading: false,
        isAddImsDistribution: false,
        isUpdateImsDistribution: false,
      };
    case constants.UPDATE_IMS_DISTRIBUTION_ERROR:
      return {
        ...state,
        updateLoading: false,
      };
    case constants.DELETE_IMS_DISTRIBUTION:
      return {
        ...state,
        deletingLoading: true,
      };
    case constants.DELETE_IMS_DISTRIBUTION_SUCCESS:
      const updatedAct = state.allImsDistributions.filter(
        (el) => el.id !== action.data.id
      );
      return {
        ...state,
        allImsDistributions: updatedAct,
        deletingLoading: false,
      };
    case constants.DELETE_IMS_DISTRIBUTION_ERROR:
      return {
        ...state,
        deletingLoading: false,
      };
    case constants.DELETE_IMS_DISTRIBUTION_ATTACH:
      return {
        ...state,
        deleteAttachLoading: true,
      };
    case constants.DELETE_IMS_DISTRIBUTION_ATTACH_SUCCESS:
      const {
        recordId,
        imgItem: { id },
      } = action.data;

      let updatedActivities = [];

      state.allImsDistributions.map((el) => {
        if (el.id === recordId) {
          let distribution_attachments = el.distribution_attachments.filter(
            (img) => img.id != id
          );
          updatedActivities.push({ ...el, distribution_attachments });
        } else {
          updatedActivities.push(el);
        }
      });

      return {
        ...state,
        allImsDistributions: updatedActivities,
        deleteAttachLoading: false,
      };
    case constants.UPDATE_IMS_DISTRIBUTION_ISADD_ISUPDATE:
      const { isAddImsDistribution, isUpdateImsDistribution } = action.data;
      return {
        ...state,
        isAddImsDistribution,
        isUpdateImsDistribution,
      };
    case constants.DELETE_IMS_DISTRIBUTION_ATTACH_ERROR:
      return {
        ...state,
        deleteAttachLoading: false,
      };

    default:
      return state;
  }
};

export default imsDistributionReducer;
