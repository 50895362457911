import * as constants from "./constants";
import { sortDepartments } from "../../Sites/utils";

const initialState = {
  allDepartments: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
};

const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_DEPARTMENTS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allDepartments: [action.data, ...state.allDepartments],
        isAdding: false,
      };
    case constants.CREATE_DEPARTMENTS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.FETCH_DEPARTMENTS:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_DEPARTMENTS_SUCCESS:

      return {
        ...state,
        fetchingLoading: false,
        allDepartments: sortDepartments(action.data),
        // totalRecords: total_departments,
        isAdding: false,
      };
    case constants.FETCH_DEPARTMENTS_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_DEPARTMENTS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_DEPARTMENTS_SUCCESS:
      const updated = state.allDepartments.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
      allDepartments: updated,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_DEPARTMENTS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_DEPARTMENTS:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_DEPARTMENTS_SUCCESS:
      const updatedData = state.allDepartments.filter(
        (el) => el.id !== action.data
      );
      return {
        ...state,
        allDepartments: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_DEPARTMENTS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    default:
      return state;
  }
};

export default departmentReducer;
