import * as constants from "./constants";

export const fetchProjectStatuses = (data) => {
  return {
    type: constants.FETCH_PROJECT_STATUSES,
    data,
  };
};

export const fetchProjectStatusesSuccess = (data) => {
  return {
    type: constants.FETCH_PROJECT_STATUSES_SUCCESS,
    data: data,
  };
};

export const fetchProjectStatusesError = (data) => {
  return {
    type: constants.FETCH_PROJECT_STATUSES_ERROR,
    data: data,
  };
};

export const updateProjectStatuses = (data) => {
  return {
    type: constants.UPDATE_PROJECT_STATUSES,
    data: data,
  };
};

export const updateProjectStatusesSuccess = (data) => {
  return {
    type: constants.UPDATE_PROJECT_STATUSES_SUCCESS,
    data: data,
  };
};

export const updateProjectStatusesError = () => {
  return {
    type: constants.UPDATE_PROJECT_STATUSES_ERROR,
  };
};

export const updateProjectsOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER,
    data: data,
  };
};

export const updateProjectsOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_SUCCESS,
    data: data,
  };
};

export const updateProjectsOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_ERROR,
  };
};

export const deleteProjectStatuses = (data) => {
  return {
    type: constants.DELETE_PROJECT_STATUSES,
    data: data,
  };
};

export const deleteProjectStatusesSuccess = (data) => {
  return {
    type: constants.DELETE_PROJECT_STATUSES_SUCCESS,
    data: data,
  };
};

export const deleteProjectStatusesError = () => {
  return {
    type: constants.DELETE_PROJECT_STATUSES_ERROR,
  };
};

export const createProjectStatuses = (data, id) => {
  return {
    type: constants.CREATE_PROJECT_STATUSES,
    data: data,
  };
};

export const createProjectStatusesSuccess = (data) => {
  return {
    type: constants.CREATE_PROJECT_STATUSES_SUCCESS,
    data: data,
  };
};

export const createProjectStatusesError = (id) => {
  return {
    type: constants.CREATE_PROJECT_STATUSES_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
