import * as constants from "./constants";

export const fetchDepartments = (data) => {
  return {
    type: constants.FETCH_DEPARTMENTS,
    data,
  };
};

export const fetchDepartmentsSuccess = (data) => {
  return {
    type: constants.FETCH_DEPARTMENTS_SUCCESS,
    data: data,
  };
};

export const fetchDepartmentsError = (data) => {
  return {
    type: constants.FETCH_DEPARTMENTS_ERROR,
    data: data,
  };
};

export const updateDepartment = (data) => {
  return {
    type: constants.UPDATE_DEPARTMENTS,
    data: data,
  };
};

export const updateDepartmentSuccess = (data) => {
  return {
    type: constants.UPDATE_DEPARTMENTS_SUCCESS,
    data: data,
  };
};

export const updateDepartmentError = () => {
  return {
    type: constants.UPDATE_DEPARTMENTS_ERROR,
  };
};

export const deleteDepartment = (data) => {
  return {
    type: constants.DELETE_DEPARTMENTS,
    data: data,
  };
};

export const deleteDepartmentSuccess = (data) => {
  return {
    type: constants.DELETE_DEPARTMENTS_SUCCESS,
    data: data,
  };
};

export const deleteDepartmentError = () => {
  return {
    type: constants.DELETE_DEPARTMENTS_ERROR,
  };
};

export const createDepartment = (data) => {
  return {
    type: constants.CREATE_DEPARTMENTS,
    data: data,
  };
};

export const createDepartmentSuccess = (data) => {
  return {
    type: constants.CREATE_DEPARTMENTS_SUCCESS,
    data: data,
  };
};

export const createDepartmentError = () => {
  return {
    type: constants.CREATE_DEPARTMENTS_ERROR,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
