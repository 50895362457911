import * as constants from "./constants";
export const fetchDocument = (data) => {
  return {
    type: constants.FETCH_DOCUMENT_CONTROL,
    data,
  };
};

export const fetchDocumentSuccess = (data) => {
  return {
    type: constants.FETCH_DOCUMENT_CONTROL_SUCCESS,
    data: data,
  };
};

export const fetchDocumentError = () => {
  return {
    type: constants.FETCH_DOCUMENT_CONTROL_ERROR,
  };
};

export const fetchDocumentSearchUtils = (data) => {
  return {
    type: constants.FETCH_DOCUMENT_CONTROL_SEARCH_DATA,
    data,
  };
};

export const fetchDocumentSearchUtilsSuccess = (data) => {
  return {
    type: constants.FETCH_DOCUMENT_CONTROL_SEARCH_DATA_SUCCESS,
    data: data,
  };
};

export const fetchDocumentSearchUtilsError = (data) => {
  return {
    type: constants.FETCH_DOCUMENT_CONTROL_SEARCH_DATA_ERROR,
    data,
  };
};
export const updateDocument = (data) => {
  return {
    type: constants.UPDATE_DOCUMENT_CONTROL,
    data: data,
  };
};

export const updateDocumentSuccess = (data) => {
  return {
    type: constants.UPDATE_DOCUMENT_CONTROL_SUCCESS,
    data: data,
  };
};

export const updateDocumentError = () => {
  return {
    type: constants.UPDATE_DOCUMENT_CONTROL_ERROR,
  };
};

export const deleteDocument = (data) => {
  return {
    type: constants.DELETE_DOCUMENT_CONTROL,
    data: data,
  };
};

export const deleteDocumentSuccess = (data) => {
  return {
    type: constants.DELETE_DOCUMENT_CONTROL_SUCCESS,
    data: data,
  };
};

export const deleteDocumentError = () => {
  return {
    type: constants.DELETE_DOCUMENT_CONTROL_ERROR,
  };
};

export const createDocument = (data) => {
  return {
    type: constants.CREATE_DOCUMENT_CONTROL,
    data: data,
  };
};

export const createDocumentSuccess = (data) => {
  return {
    type: constants.CREATE_DOCUMENT_CONTROL_SUCCESS,
    data: data,
  };
};

export const createDocumentError = () => {
  return {
    type: constants.CREATE_DOCUMENT_CONTROL_ERROR,
  };
};

export const deleteDocumentAttach = (data) => {
  return {
    type: constants.DELETE_DOCUMENT_CONTROL_ATTACH,
    data: data,
  };
};

export const deleteDocumentAttachSuccess = (data) => {
  return {
    type: constants.DELETE_DOCUMENT_CONTROL_ATTACH_SUCCESS,
    data: data,
  };
};

export const deleteDocumentAttachError = () => {
  return {
    type: constants.DELETE_DOCUMENT_CONTROL_ATTACH_ERROR,
  };
};

export const updateIsAddIsUpdate = (data) => {
  return {
    type: constants.UPDATE_ISADD_ISUPDATE,
    data,
  };
};

export const addDocumentAttach = (data) => {
  return {
    type: constants.ADD_DOCUMENT_CONTROL_ATTACH,
    data: data,
  };
};
