const FETCH_DOCUMENT_FIELDS = "FETCH_DOCUMENT_FIELDS";
const FETCH_DOCUMENT_FIELDS_SUCCESS = "FETCH_DOCUMENT_FIELDS_SUCCESS";
const FETCH_DOCUMENT_FIELDS_ERROR = "FETCH_DOCUMENT_FIELDS_ERROR";
const UPDATE_DOCUMENT_FIELDS = "UPDATE_DOCUMENT_FIELDS";
const UPDATE_DOCUMENT_FIELDS_SUCCESS = "UPDATE_DOCUMENT_FIELDS_SUCCESS";
const UPDATE_DOCUMENT_FIELDS_ERROR = "UPDATE_DOCUMENT_FIELDS_ERROR";
const IS_ADDING_UPDATE = "IS_ADDING_UPDATE";

export {
  FETCH_DOCUMENT_FIELDS,
  FETCH_DOCUMENT_FIELDS_SUCCESS,
  FETCH_DOCUMENT_FIELDS_ERROR,
  UPDATE_DOCUMENT_FIELDS,
  UPDATE_DOCUMENT_FIELDS_SUCCESS,
  UPDATE_DOCUMENT_FIELDS_ERROR,
  IS_ADDING_UPDATE,
};
