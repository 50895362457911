const LOGIN = "LOGIN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_ERROR = "LOGIN_ERROR";
const LOGOUT = "LOGOUT";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
const LOGOUT_ERROR = "LOGOUT_ERROR";
const SET_USER = "SET_USER";
const FETCH_SIDEBAR_PROJECTS = "FETCH_SIDEBAR_PROJECTS";
const FETCH_SIDEBAR_PROJECTS_SUCCESS = "FETCH_SIDEBAR_PROJECTS_SUCCESS";
const FETCH_SIDEBAR_PROJECTS_ERROR = "FETCH_SIDEBAR_PROJECTS_ERROR";
const NAVBAR_SET_COLLAPSED = "NAVBAR_SET_COLLAPSED";

export {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SET_USER,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  FETCH_SIDEBAR_PROJECTS,
  FETCH_SIDEBAR_PROJECTS_SUCCESS,
  FETCH_SIDEBAR_PROJECTS_ERROR,
  NAVBAR_SET_COLLAPSED
};
