import React, { useEffect, useState } from 'react';
import { Col, Input, Row, Form, Select, message, InputNumber } from 'antd';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { createNpo, setNpoDetailsIsEdit, updateNpo } from '../redux/actions';
import SpinnerComponent from '../../../components/spinner';
// import { isValidNumber } from '../../../globalUtils/axiosConfig';
import AttachmentsSection from './attachmentsSection';
import { useLocation } from 'react-router-dom';
// import { fetchUserDetailsUtils } from '../../UserManager/redux/actions';

let pathRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
const booksOptions = [
  {
    key: 'file',
    label: 'Upload File',
  },
  {
    key: 'remote',
    label: 'Remote URL',
  },
];

const BookDetailsSection = ({ isNpoUpdate, canUpdate, learningActivities }) => {
  const [documents, setDocuments] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [utils, setUtils] = useState({});
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [currentNpo, setCurrentNpo] = useState({
    name: '',
    pdf_path: '',
  });

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  // const { TextArea } = Input;
  const { Option } = Select;

  const { createNpoLoading, currentNpoObj, npoDetailsIsEdit, utilsData } =
    useSelector((state) => state.npoSetupReducer);

  // const { allNpoStatuses } = useSelector((state) => state.npoStatusReducer);
  // const { allNpoRegions } = useSelector((state) => state.npoRegionsReducer);

  // useEffect(() => {
  //   dispatch(fetchUserDetailsUtils());
  // }, []);

  useEffect(() => {
    if (currentNpoObj?._id) {
      setCurrentNpo(currentNpoObj);
      const {
        name,
        pdf_path,
        learningActivities,
        first_page_number,
        last_page_number,
      } = currentNpoObj;
      const { url, source } = pdf_path;

      form.setFieldsValue({
        name,
        source,
        first_page_number,
        last_page_number,
      });

      if (source === 'remote') {
        form.setFieldsValue({ url });
      } else if (source === 'file') {
        let pdfName = url.split('/')[url.split('/').length - 1].split('?')[0];

        let img = {
          key: pdfName,
          pdf_path: url,
          isLocal: false,
          name: pdfName,
        };

        setDocuments([img]);
      }

      let selected =
        learningActivities?.map((item) => item.learning_activity) || [];
      setSelectedActivities(selected);
    }
    // }
    // setUtils(utilsData);
  }, [currentNpoObj]); // utilsData

  useEffect(() => {
    setLoading(createNpoLoading);
    // if (createNpoLoading) {
    //   setIsUserImgSelected(false);
    // }
  }, [createNpoLoading]);

  useEffect(() => {
    if (pathname.includes('create') && currentNpoObj?._id === undefined) {
      setIsEdit(true);
    } else {
      setIsEdit(npoDetailsIsEdit);
    }
  }, [npoDetailsIsEdit, currentNpoObj]);

  const onFinish = (values) => {
    const { ...res } = values;

    const { source } = values;
    let docs = [];
    let isBookUpdated = false;
    if (source === 'remote') {
      let prevUrl = currentNpoObj?.pdf_path?.url;
      let UpdatedUrl = values.url;
      isBookUpdated = prevUrl !== UpdatedUrl;
      docs = [];
    }
    if (source === 'file') {
      delete res.url;
      if (documents.length === 0) {
        return message.error('Please upload a file');
      } else {
        docs = documents;
        isBookUpdated = documents[0].isLocal;
      }
    }

    let prevSelected =
      currentNpoObj?.learningActivities?.map(
        (item) => item.learning_activity
      ) || [];

    const deletedActivities = prevSelected
      .filter((id) => !selectedActivities.includes(id))
      .map((id) => ({ id, action: 'remove' }));

    // Find new added activities
    const addedActivities = selectedActivities
      .filter((id) => !prevSelected.includes(id))
      .map((id) => ({ id, action: 'add' }));

    let learning_activities = [...deletedActivities, ...addedActivities];

    isNpoUpdate
      ? dispatch(
          updateNpo({
            ...res,
            ...{ documents: docs },
            learning_activities,
            id: currentNpoObj._id,
            isBookUpdated,
          })
        )
      : dispatch(
          createNpo({
            ...res,
            documents: docs,
          })
        );
  };
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const handleSave = () => {
    form.submit();
  };

  const handleChange = (value) => {
    setSelectedActivities(value);
  };

  // const capitalizeFirstLetter = (str) =>
  //   str && str.charAt(0).toUpperCase() + str.slice(1);

  const source = Form.useWatch('source', form);

  return (
    <>
      <Row>
        <Col span={24} className='heading-col'>
          <Row align='middle' justify='space-between'>
            <Col>
              <div>
                <span className='heading-span'>BOOK Details</span>
              </div>
            </Col>
            <Col>
              {isEdit ? (
                loading ? (
                  <SpinnerComponent fontSize={14} />
                ) : (
                  <SaveIcon width={16} height={16} onClick={handleSave} />
                )
              ) : (
                canUpdate && (
                  <EditIcon
                    width={16}
                    fill='#e21c21'
                    height={16}
                    onClick={() => dispatch(setNpoDetailsIsEdit(true))}
                  />
                )
              )}
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className={`user-details-section project-details-section ${
            !isEdit && 'is-edit'
          }`}
        >
          <Row justify='center'>
            <Col md={24} lg={23} xl={20} xxl={20}>
              <Form
                {...formItemLayout}
                form={form}
                name='user-details'
                onFinish={onFinish}
                initialValues={currentNpo}
                scrollToFirstError
                labelWrap={true}
                layout='horizontal'
                className='user-detail-form'
                requiredMark={false}
              >
                <Row gutter={[20, 10]}>
                  <Col span={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='name'
                      label='Title *'
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        placeholder='Book Title'
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='first_page_number'
                      label='First Page Number *'
                    >
                      <InputNumber
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        min={0}
                        placeholder="Book's first Page Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='last_page_number'
                      label='Last page number *'
                    >
                      <InputNumber
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        placeholder="Book's last page number"
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  {isNpoUpdate && (
                    <Col span={24}>
                      <Form.Item label='Learning Activities'>
                        <Select
                          mode='multiple'
                          size='middle'
                          style={{
                            width: '100%',
                          }}
                          disabled={!isEdit}
                          placeholder='Select Learning Activities'
                          value={selectedActivities}
                          onChange={handleChange}
                          options={learningActivities}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {/* <Col span={24}>
                    <Row gutter={[0, 10]}> */}
                  <Col span={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='source'
                      label='File Type *'
                    >
                      <Select
                        style={{
                          width: '100%',
                        }}
                        disabled={!isEdit || !canUpdate}
                        readOnly={true}
                        className='department-select'
                        dropdownClassName='select-dropdown-custom'
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            'ant-layout-content layout-content site-layout-background custom-textarea'
                          )[0]
                        }
                        showSearch
                        placeholder='Select File Type'
                        optionFilterProp='children'
                        // onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {booksOptions.map(({ label, key }) => {
                          return (
                            <Option key={key} value={key}>
                              {label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    {source === 'file' && (
                      <AttachmentsSection
                        isEdit={isEdit}
                        loading={loading}
                        documents={documents}
                        setDocuments={setDocuments}
                        currentNpo={currentNpo}
                      />
                    )}
                    {source === 'remote' && (
                      // <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Required!',
                          },
                          {
                            pattern: pathRegex,
                            message: 'Invalid URL',
                          },
                        ]}
                        name='url'
                        label='Remote URL *'
                      >
                        {isEdit ? (
                          <Input
                            readOnly={loading}
                            disabled={!isEdit || !canUpdate}
                            placeholder='Enter Remote URL'
                          />
                        ) : (
                          <>
                            <div
                              style={{
                                paddingLeft: '12px',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                let url = form.getFieldValue('url');
                                if (url) {
                                  window.open(url, '_blank');
                                }
                              }}
                            >
                              {form.getFieldValue('url')}
                            </div>
                          </>
                        )}
                      </Form.Item>
                      // </Col>
                    )}
                  </Col>
                  {/* </Row>
                  </Col> */}
                  {/* <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='status_id'
                      label='NPO Status'
                    >
                      <Select
                        style={{
                          width: '100%',
                        }}
                        disabled={!isEdit || !canUpdate}
                        readOnly={true}
                        className='department-select'
                        dropdownClassName='select-dropdown-custom'
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            'ant-layout-content layout-content site-layout-background custom-textarea'
                          )[0]
                        }
                        showSearch
                        placeholder='Select NPO Status'
                        optionFilterProp='children'
                        onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {allNpoStatuses.map(({ id, name }) => {
                          return (
                            <Option key={id} value={id}>
                              {name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='contact_name'
                      label='Director Name'
                    >
                      <Input
                        readOnly={loading}
                        disabled={
                          !isEdit ||
                          // isNpoUpdate ||
                          !canUpdate
                        }
                        placeholder='Director Name'
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='contact_no'
                      label='Director Contact Number'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit} // || isNpoUpdate
                        placeholder='Director Contact Number'
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='email'
                      label='Director Email'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid Email!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate} // || isUserUpdate
                        placeholder='Director Email'
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='manager_name'
                      label='PROGRAMME MANAGER NAME'
                    >
                      <Input
                        readOnly={loading}
                        disabled={
                          !isEdit ||
                          // isNpoUpdate ||
                          !canUpdate
                        }
                        placeholder='Programme Manager Name'
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='manager_no'
                      label='PROGRAMME MANAGER NUMBER'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit} // || isNpoUpdate
                        placeholder='Programme Manager Number'
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='manager_email'
                      label='PROGRAMME MANAGER EMAIL'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid Email!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate} // || isUserUpdate
                        placeholder='Programme Manager Email'
                      />
                    </Form.Item>
                  </Col> */}
                  {/* <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='project_type'
                      label='Type of NPO'
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        placeholder='Type of NPO'
                      />
                    </Form.Item>
                  </Col> */}

                  {/* <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='onboard_date'
                      label='NPO Onboard Date'
                    >
                      <DatePicker
                        style={{
                          width: '100%',
                        }}
                        format='D-MM-YYYY'
                        allowClear={false}
                        suffixIcon={null}
                        placeholder='NPO Onboard Date'
                        disabled={!isEdit || !canUpdate || loading}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={16} lg={16} xl={16} xxl={16}>
                    <Form.Item
                      name='address'
                      label='NPO Address'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit} // || isNpoUpdate
                        placeholder='NPO Address'
                      />
                    </Form.Item>
                  </Col> */}
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className='empty-div'></div>
    </>
  );
};

export default BookDetailsSection;
