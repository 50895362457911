const FETCH_DOCUMENT_CONTROL = "FETCH_DOCUMENT_CONTROL";
const FETCH_DOCUMENT_CONTROL_SUCCESS = "FETCH_DOCUMENT_CONTROL_SUCCESS";
const FETCH_DOCUMENT_CONTROL_ERROR = "FETCH_DOCUMENT_CONTROL_ERROR";
const FETCH_DOCUMENT_CONTROL_GRAPH_DATA = "FETCH_DOCUMENT_CONTROL_GRAPH_DATA";
const FETCH_DOCUMENT_CONTROL_GRAPH_DATA_SUCCESS ="FETCH_DOCUMENT_CONTROL_GRAPH_DATA_SUCCESS";
const FETCH_DOCUMENT_CONTROL_GRAPH_DATA_ERROR = "FETCH_DOCUMENT_CONTROL_GRAPH_DATA_ERROR";
const UPDATE_DOCUMENT_CONTROL = "UPDATE_DOCUMENT_CONTROL";
const UPDATE_DOCUMENT_CONTROL_SUCCESS = "UPDATE_DOCUMENT_CONTROL_SUCCESS";
const UPDATE_DOCUMENT_CONTROL_ERROR = "UPDATE_DOCUMENT_CONTROL_ERROR";
const FETCH_DOCUMENT_CONTROL_SEARCH_DATA = "FETCH_DOCUMENT_CONTROL_SEARCH_DATA";
const FETCH_DOCUMENT_CONTROL_SEARCH_DATA_SUCCESS ="FETCH_DOCUMENT_CONTROL_SEARCH_DATA_SUCCESS";
const FETCH_DOCUMENT_CONTROL_SEARCH_DATA_ERROR = "FETCH_DOCUMENT_CONTROL_SEARCH_DATA_ERROR";
const DELETE_DOCUMENT_CONTROL = "DELETE_DOCUMENT_CONTROL";
const DELETE_DOCUMENT_CONTROL_SUCCESS = "DELETE_DOCUMENT_CONTROL_SUCCESS";
const DELETE_DOCUMENT_CONTROL_ERROR = "DELETE_DOCUMENT_CONTROL_ERROR";
const CREATE_DOCUMENT_CONTROL = "CREATE_DOCUMENT_CONTROL";
const CREATE_DOCUMENT_CONTROL_SUCCESS = "CREATE_DOCUMENT_CONTROL_SUCCESS";
const CREATE_DOCUMENT_CONTROL_ERROR = "CREATE_DOCUMENT_CONTROL_ERROR";
const DELETE_DOCUMENT_CONTROL_ATTACH = "DELETE_DOCUMENT_CONTROL_ATTACH";
const DELETE_DOCUMENT_CONTROL_ATTACH_SUCCESS = "DELETE_DOCUMENT_CONTROL_ATTACH_SUCCESS";
const DELETE_DOCUMENT_CONTROL_ATTACH_ERROR = "DELETE_DOCUMENT_CONTROL_ATTACH_ERROR";
const UPDATE_ISADD_ISUPDATE = "UPDATE_ISADD_ISUPDATE";
const ADD_DOCUMENT_CONTROL_ATTACH = "ADD_DOCUMENT_CONTROL_ATTACH";

export {
  FETCH_DOCUMENT_CONTROL,
  FETCH_DOCUMENT_CONTROL_SUCCESS,
  FETCH_DOCUMENT_CONTROL_ERROR,
  FETCH_DOCUMENT_CONTROL_GRAPH_DATA,
  FETCH_DOCUMENT_CONTROL_GRAPH_DATA_SUCCESS,
  FETCH_DOCUMENT_CONTROL_GRAPH_DATA_ERROR,
  FETCH_DOCUMENT_CONTROL_SEARCH_DATA,
  FETCH_DOCUMENT_CONTROL_SEARCH_DATA_SUCCESS,
  FETCH_DOCUMENT_CONTROL_SEARCH_DATA_ERROR,
  UPDATE_DOCUMENT_CONTROL,
  UPDATE_DOCUMENT_CONTROL_SUCCESS,
  UPDATE_DOCUMENT_CONTROL_ERROR,
  DELETE_DOCUMENT_CONTROL,
  DELETE_DOCUMENT_CONTROL_SUCCESS,
  DELETE_DOCUMENT_CONTROL_ERROR,
  CREATE_DOCUMENT_CONTROL,
  CREATE_DOCUMENT_CONTROL_SUCCESS,
  CREATE_DOCUMENT_CONTROL_ERROR,
  DELETE_DOCUMENT_CONTROL_ATTACH,
  DELETE_DOCUMENT_CONTROL_ATTACH_SUCCESS,
  DELETE_DOCUMENT_CONTROL_ATTACH_ERROR,
  UPDATE_ISADD_ISUPDATE,
  ADD_DOCUMENT_CONTROL_ATTACH,
};
