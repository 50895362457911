const FETCH_LEARNER_TYPES = 'FETCH_LEARNER_TYPES';
const FETCH_LEARNER_TYPES_SUCCESS = 'FETCH_LEARNER_TYPES_SUCCESS';
const FETCH_LEARNER_TYPES_ERROR = 'FETCH_LEARNER_TYPES_ERROR';
const UPDATE_LEARNER_TYPES = 'UPDATE_LEARNER_TYPES';
const UPDATE_LEARNER_TYPES_SUCCESS = 'UPDATE_LEARNER_TYPES_SUCCESS';
const UPDATE_LEARNER_TYPES_ERROR = 'UPDATE_LEARNER_TYPES_ERROR';
const DELETE_LEARNER_TYPES = 'DELETE_LEARNER_TYPES';
const DELETE_LEARNER_TYPES_SUCCESS = 'DELETE_LEARNER_TYPES_SUCCESS';
const DELETE_LEARNER_TYPES_ERROR = 'DELETE_LEARNER_TYPES_ERROR';
const CREATE_LEARNER_TYPES = 'CREATE_LEARNER_TYPES';
const CREATE_LEARNER_TYPES_SUCCESS = 'CREATE_LEARNER_TYPES_SUCCESS';
const CREATE_LEARNER_TYPES_ERROR = 'CREATE_LEARNER_TYPES_ERROR';
const UPDATE_ORDER_LEARNER_TYPES = 'UPDATE_ORDER_LEARNER_TYPES';
const UPDATE_ORDER_LEARNER_TYPES_SUCCESS = 'UPDATE_ORDER_LEARNER_TYPES_SUCCESS';
const UPDATE_ORDER_LEARNER_TYPES_ERROR = 'UPDATE_ORDER_LEARNER_TYPES_ERROR';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';

export {
  FETCH_LEARNER_TYPES,
  FETCH_LEARNER_TYPES_SUCCESS,
  FETCH_LEARNER_TYPES_ERROR,
  UPDATE_LEARNER_TYPES,
  UPDATE_LEARNER_TYPES_SUCCESS,
  UPDATE_LEARNER_TYPES_ERROR,
  DELETE_LEARNER_TYPES,
  DELETE_LEARNER_TYPES_SUCCESS,
  DELETE_LEARNER_TYPES_ERROR,
  CREATE_LEARNER_TYPES,
  CREATE_LEARNER_TYPES_SUCCESS,
  CREATE_LEARNER_TYPES_ERROR,
  UPDATE_ORDER_LEARNER_TYPES,
  UPDATE_ORDER_LEARNER_TYPES_SUCCESS,
  UPDATE_ORDER_LEARNER_TYPES_ERROR,
  IS_ADDING_UPDATE,
};
