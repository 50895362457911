import * as constants from './constants';

export const fetchCoachesDocs = (data) => {
  return {
    type: constants.FETCH_COACHES_DOCS,
    data: data,
  };
};

export const fetchCoachesDocsSuccess = (data) => {
  return {
    type: constants.FETCH_COACHES_DOCS_SUCCESS,
    data: data,
  };
};

export const fetchCoachesDocsError = () => {
  return {
    type: constants.FETCH_COACHES_DOCS_ERROR,
  };
};

export const fetchActivities = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES,
    data,
  };
};

export const fetchActivitiesSuccess = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SUCCESS,
    data: data,
  };
};

export const fetchActivitiesSearchUtils = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA,
    data,
  };
};

export const fetchActivitiesSearchUtilsSuccess = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS,
    data: data,
  };
};

export const fetchActivitiesSearchUtilsError = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA_ERROR,
    data,
  };
};

export const fetchBatchUpdate = (data) => {
  return {
    type: constants.FETCH_BATCH_UPDATE,
    data: data,
  };
};

export const fetchBatchUpdateSuccess = (data) => {
  return {
    type: constants.FETCH_BATCH_UPDATE_SUCCESS,
    data: data,
  };
};

export const fetchBatchUpdateError = () => {
  return {
    type: constants.FETCH_BATCH_UPDATE_ERROR,
  };
};
