import * as constants from './constants';

export const fetchCoachStatuses = (data) => {
  return {
    type: constants.FETCH_COACH_STATUSES,
    data,
  };
};

export const fetchCoachStatusesSuccess = (data) => {
  return {
    type: constants.FETCH_COACH_STATUSES_SUCCESS,
    data: data,
  };
};

export const fetchCoachStatusesError = (data) => {
  return {
    type: constants.FETCH_COACH_STATUSES_ERROR,
    data: data,
  };
};

export const updateCoachStatuses = (data) => {
  return {
    type: constants.UPDATE_COACH_STATUSES,
    data: data,
  };
};

export const updateCoachStatusesSuccess = (data) => {
  return {
    type: constants.UPDATE_COACH_STATUSES_SUCCESS,
    data: data,
  };
};

export const updateCoachStatusesError = () => {
  return {
    type: constants.UPDATE_COACH_STATUSES_ERROR,
  };
};

export const updateCoachOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_COACH_STATUS,
    data: data,
  };
};

export const updateCoachOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_COACH_STATUS_SUCCESS,
    data: data,
  };
};

export const updateCoachOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_COACH_STATUS_ERROR,
  };
};

export const deleteCoachStatuses = (data) => {
  return {
    type: constants.DELETE_COACH_STATUSES,
    data: data,
  };
};

export const deleteCoachStatusesSuccess = (data) => {
  return {
    type: constants.DELETE_COACH_STATUSES_SUCCESS,
    data: data,
  };
};

export const deleteCoachStatusesError = () => {
  return {
    type: constants.DELETE_COACH_STATUSES_ERROR,
  };
};

export const createCoachStatuses = (data, id) => {
  return {
    type: constants.CREATE_COACH_STATUSES,
    data: data,
  };
};

export const createCoachStatusesSuccess = (data) => {
  return {
    type: constants.CREATE_COACH_STATUSES_SUCCESS,
    data: data,
  };
};

export const createCoachStatusesError = (id) => {
  return {
    type: constants.CREATE_COACH_STATUSES_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
