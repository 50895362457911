import * as constants from './constants';

const initialState = {
  allNpoRegions: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
};

const NpoRegionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_NPO_REGIONS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_NPO_REGIONS_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allNpoRegions: [...state.allNpoRegions, action.data],
        isAdding: false,
      };
    case constants.CREATE_NPO_REGIONS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.UPDATE_ORDER_NPO_REGIONS:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.UPDATE_ORDER_NPO_REGIONS_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allNpoRegions: action.data,
      };
    case constants.UPDATE_ORDER_NPO_REGIONS_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.FETCH_NPO_REGIONS:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_NPO_REGIONS_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allNpoRegions: action.data,
        isAdding: false,
      };
    case constants.FETCH_NPO_REGIONS_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_NPO_REGIONS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_NPO_REGIONS_SUCCESS:
      const updated = state.allNpoRegions.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allNpoRegions: updated,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_NPO_REGIONS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_NPO_REGIONS:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_NPO_REGIONS_SUCCESS:
      const updatedData = state.allNpoRegions.filter(
        (el) => el.id !== action.data
      );
      return {
        ...state,
        allNpoRegions: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_NPO_REGIONS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    default:
      return state;
  }
};

export default NpoRegionsReducer;
