const FETCH_NPO_STATUSES= "FETCH_NPO_STATUSES";
const FETCH_NPO_STATUSES_SUCCESS = "FETCH_NPO_STATUSES_SUCCESS";
const FETCH_NPO_STATUSES_ERROR = "FETCH_NPO_STATUSES_ERROR";
const UPDATE_NPO_STATUSES = "UPDATE_NPO_STATUSES";
const UPDATE_NPO_STATUSES_SUCCESS = "UPDATE_NPO_STATUSES_SUCCESS";
const UPDATE_NPO_STATUSES_ERROR = "UPDATE_NPO_STATUSES_ERROR";
const DELETE_NPO_STATUSES = "DELETE_NPO_STATUSES";
const DELETE_NPO_STATUSES_SUCCESS = "DELETE_NPO_STATUSES_SUCCESS";
const DELETE_NPO_STATUSES_ERROR = "DELETE_NPO_STATUSES_ERROR";
const CREATE_NPO_STATUSES = "CREATE_NPO_STATUSES";
const CREATE_NPO_STATUSES_SUCCESS = "CREATE_NPO_STATUSES_SUCCESS";
const CREATE_NPO_STATUSES_ERROR = "CREATE_NPO_STATUSES_ERROR";
const UPDATE_ORDER_NPO_STATUS = "UPDATE_ORDER_NPO_STATUS";
const UPDATE_ORDER_NPO_STATUS_SUCCESS = "UPDATE_ORDER_NPO_STATUS_SUCCESS";
const UPDATE_ORDER_NPO_STATUS_ERROR = "UPDATE_ORDER_NPO_STATUS_ERROR";
const IS_ADDING_UPDATE = "IS_ADDING_UPDATE";

export {
  FETCH_NPO_STATUSES,
  FETCH_NPO_STATUSES_SUCCESS,
  FETCH_NPO_STATUSES_ERROR,
  UPDATE_NPO_STATUSES,
  UPDATE_NPO_STATUSES_SUCCESS,
  UPDATE_NPO_STATUSES_ERROR,
  DELETE_NPO_STATUSES,
  DELETE_NPO_STATUSES_SUCCESS,
  DELETE_NPO_STATUSES_ERROR,
  CREATE_NPO_STATUSES,
  CREATE_NPO_STATUSES_SUCCESS,
  CREATE_NPO_STATUSES_ERROR,
  UPDATE_ORDER_NPO_STATUS,
  UPDATE_ORDER_NPO_STATUS_SUCCESS,
  UPDATE_ORDER_NPO_STATUS_ERROR,
  IS_ADDING_UPDATE,
};
