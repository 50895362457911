import * as constants from './constants';

const initialState = {
  allTemplates: [],
  createTemplateLoading: false,
  currentTemplateObj: {
    name: '',
    mass_name: 'Mass Class',
    manuals_attributes: [],
    modular_target: 0,
    totalMassWeight: 0,
    mass_weight: 0,
    modular_weight: 0,
    mass_target: 0,
    any_manual_weight: 0,
    any_manual_pre: 0,
    any_manual_post: 0,
    include_campus: false,
  },
  templateCreatedSuccess: false,
  templateEditSuccess: false,
  // fetchingLoading: false,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
  fetchTemplateLoading: false,
  documentTemplateIsEdit: false,
  deleteTemplateLoading: false,
};

const interventionTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.INTERVENTION_TYPE_IS_EDIT:
      return {
        ...state,
        documentTemplateIsEdit: action.data,
      };
    case constants.CREATE_INTERVENTION_TYPE:
      return {
        ...state,
        templateEditSuccess: false,
        createTemplateLoading: true,
        templateCreatedSuccess: false,
        DocumentTemplateIsEdit: false,
      };
    case constants.CREATE_INTERVENTION_TYPE_SUCCESS:
      const { manuals, ...rest } = action.data;
      const templateFields = manuals.map((el) => ({
        ...el,
        // isEdit: false,
        isLocal: false,
      }));
      return {
        ...state,
        createTemplateLoading: false,
        DocumentTemplateIsEdit: false,
        templateCreatedSuccess: true,
        templateEditSuccess: false,
        currentTemplateObj: {
          ...rest,
          manuals_attributes: templateFields,
        },
      };
    case constants.CREATE_INTERVENTION_TYPE_ERROR:
      return {
        ...state,
        createTemplateLoading: false,
        templateCreatedSuccess: false,
      };
    case constants.FETCH_ALL_INTERVENTION_TYPES:
      return {
        ...state,
        fetchTemplateLoading: true,
        templateCreatedSuccess: false,
        templateEditSuccess: false,
      };
    case constants.FETCH_ALL_INTERVENTION_TYPES_SUCCESS:
      // const { templates, total_document_templates } = action.data;
      return {
        ...state,
        fetchTemplateLoading: false,
        isAdding: false,
        allTemplates: action.data,
        totalRecords: action.data.length,
        templateCreatedSuccess: false,
        DocumentTemplateIsEdit: false,
        templateEditSuccess: false,
      };
    case constants.FETCH_ALL_INTERVENTION_TYPES_ERROR:
      return {
        ...state,
        fetchTemplateLoading: false,
        isAdding: false,
        templateCreatedSuccess: false,
        templateEditSuccess: false,
      };
    case constants.UPDATE_INTERVENTION_TYPE:
      return {
        ...state,
        updateLoading: true,
        templateCreatedSuccess: false,
      };
    case constants.UPDATE_INTERVENTION_TYPE_SUCCESS:
      // const updated = state.allTemplates.map((el) =>
      //   el.id === action.data.id ? { ...el, ...action.data } : el
      // );
      return {
        ...state,
        // allTemplate: updated,
        updateLoading: false,
        isAdding: false,
        templateCreatedSuccess: false,
        DocumentTemplateIsEdit: false,
        templateEditSuccess: true,
      };
    case constants.UPDATE_INTERVENTION_TYPE_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
        templateCreatedSuccess: false,
      };
    case constants.DELETE_INTERVENTION_TYPE:
      return {
        ...state,
        templateEditSuccess: false,
        deleteTemplateLoading: true,
        templateCreatedSuccess: false,
        DocumentTemplateIsEdit: false,
      };
    case constants.DELETE_INTERVENTION_TYPE_SUCCESS:
      let updatedTemplate = state.allTemplates.filter(
        (el) => el.id !== action.data.id
      );
      let totalRecords = state.totalRecords - 1;
      return {
        ...state,
        totalRecords: totalRecords,
        fetchTemplatesLoading: false,
        allTemplates: updatedTemplate,
      };
    case constants.DELETE_INTERVENTION_TYPE_ERROR:
      return {
        ...state,
        fetchTemplatesLoading: false,
      };

    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };
    case constants.FETCH_INTERVENTION_TYPE:
      return {
        ...state,
        fetchTemplateLoading: true,
        templateCreatedSuccess: false,
      };
    case constants.FETCH_INTERVENTION_TYPE_SUCCESS:
      const { data } = action;

      const updatedTemplateFields = data.user_manuals
        .filter((el) => !el.is_deleted)
        .map((el) => ({
          ...el,
          // isEdit: false,
          isLocal: false,
        }));

      return {
        ...state,
        fetchTemplateLoading: false,
        templateCreatedSuccess: false,
        currentTemplateObj: {
          ...data,
          manuals_attributes: updatedTemplateFields,
        },
      };
    case constants.FETCH_INTERVENTION_TYPE_ERROR:
      return {
        ...state,
        fetchTemplateLoading: false,
        templateCreatedSuccess: false,
      };
    // default:
    //   return state;
    case constants.INTERVENTION_TYPE_CREATION_CLEANUP_FUNC:
      return {
        ...state,
        allTemplates: [],
        createTemplateLoading: false,
        currentProjectObj: {},
        templateDetailsIsEdit: false,
        fetchTemplateLoading: false,
        deletePTemplateLoading: false,
        templateEditSuccess: false,
        currentTemplateObj: {
          name: '',
          manuals_attributes: [],
          mass_name: 'Mass Class',
          totalMassWeight: 0,
          mass_weight: 0,
          modular_target: 0,
          modular_weight: 0,
          mass_target: 0,
          any_manual_weight: 0,
          any_manual_pre: 0,
          any_manual_post: 0,
          include_campus: false,
        },
        // projectNotificationIsEdit: false,
      };
    default:
      return state;
  }
};

export default interventionTypesReducer;
