import * as constants from "./constants";

const initialState = {
  allProjectRevisions: [],
  fetchingLoading: false,
  updateLoading: false,
  deleteAttachLoading: false,
  fetchUtilsLoading: false,
  isAddProjectRevision: false,
  isUpdateProjectRevision: false,
  deletingLoading: false,
};

const projectRevisionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECT_REVISIONS:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_PROJECT_REVISIONS_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allProjectRevisions: action.data,
      };
    case constants.FETCH_PROJECT_REVISIONS_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.CREATE_PROJECT_REVISIONS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_PROJECT_REVISIONS_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allProjectRevisions: [action.data, ...state.allProjectRevisions],
        isAddProjectRevision: false,
        isUpdateProjectRevision: false,
      };
    case constants.CREATE_PROJECT_REVISIONS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAddProjectRevision: false,
        isUpdateProjectRevision: false,
      };
    // case constants.FETCH_ACTIVITIES_SEARCH_DATA:
    //   return {
    //     ...state,
    //     fetchUtilsLoading: true,
    //   };
    // case constants.FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS:
    //   const { activity_types, sales_reps } = action.data;

    //   const sortedActivityTypes = sortActivityTypes(activity_types);
    //   const sortedSalesRep = sortActivitiesSalesRep(sales_reps);

    //   let activityTypesOptions = sortedActivityTypes.map((el) => {
    //     const { activity_type, id } = el;
    //     return { value: id, label: activity_type };
    //   });

    //   let salesRepOptions = sortedSalesRep.map((el) => {
    //     const { username, id } = el;
    //     return { value: id, label: username };
    //   });

    //   return {
    //     ...state,
    //     fetchUtilsLoading: false,
    //     salesRepOptions,
    //     activityTypesOptions,
    //   };
    // case constants.FETCH_ACTIVITIES_SEARCH_DATA_ERROR:
    //   return {
    //     ...state,
    //     fetchUtilsLoading: false,
    //   };

    case constants.UPDATE_PROJECT_REVISIONS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_PROJECT_REVISIONS_SUCCESS:
      const updated = state.allProjectRevisions.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allProjectRevisions: updated,
        updateLoading: false,
        isAddProjectRevision: false,
        isUpdateProjectRevision: false,
      };
    case constants.UPDATE_PROJECT_REVISIONS_ERROR:
      return {
        ...state,
        updateLoading: false,
      };
    case constants.DELETE_PROJECT_REVISIONS:
      return {
        ...state,
        deletingLoading: true,
      };
    case constants.DELETE_PROJECT_REVISIONS_SUCCESS:
      const updatedAct = state.allProjectRevisions.filter(
        (el) => el.id !== action.data.id
      );
      return {
        ...state,
        allProjectRevisions: updatedAct,
        deletingLoading: false,
      };
    case constants.DELETE_PROJECT_REVISIONS_ERROR:
      return {
        ...state,
        deletingLoading: false,
      };
    case constants.DELETE_PROJECT_REVISIONS_ATTACH:
      return {
        ...state,
        deleteAttachLoading: true,
      };
    case constants.DELETE_PROJECT_REVISIONS_ATTACH_SUCCESS:
      const {
        recordId,
        imgItem: { id },
      } = action.data;

      let updatedActivities = [];

      state.allProjectRevisions.map((el) => {
        if (el.id === recordId) {
          let revision_attachments = el.revision_attachments.filter(
            (img) => img.id != id
          );
          updatedActivities.push({ ...el, revision_attachments });
        } else {
          updatedActivities.push(el);
        }
      });

      return {
        ...state,
        allProjectRevisions: updatedActivities,
        deleteAttachLoading: false,
      };
    case constants.UPDATE_PROJECT_REVISIONS_ISADD_ISUPDATE:
      const { isAddProjectRevision, isUpdateProjectRevision } = action.data;
      return {
        ...state,
        isAddProjectRevision,
        isUpdateProjectRevision,
      };
    case constants.DELETE_PROJECT_REVISIONS_ATTACH_ERROR:
      return {
        ...state,
        deleteAttachLoading: false,
      };
    default:
      return state;
  }
};

export default projectRevisionsReducer;
