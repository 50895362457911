const FETCH_PROGRAMME_TYPES = 'FETCH_PROGRAMME_TYPES';
const FETCH_PROGRAMME_TYPES_SUCCESS = 'FETCH_PROGRAMME_TYPES_SUCCESS';
const FETCH_PROGRAMME_TYPES_ERROR = 'FETCH_PROGRAMME_TYPES_ERROR';
const UPDATE_PROGRAMME_TYPES = 'UPDATE_PROGRAMME_TYPES';
const UPDATE_PROGRAMME_TYPES_SUCCESS = 'UPDATE_PROGRAMME_TYPES_SUCCESS';
const UPDATE_PROGRAMME_TYPES_ERROR = 'UPDATE_PROGRAMME_TYPES_ERROR';
const DELETE_PROGRAMME_TYPES = 'DELETE_PROGRAMME_TYPES';
const DELETE_PROGRAMME_TYPES_SUCCESS = 'DELETE_PROGRAMME_TYPES_SUCCESS';
const DELETE_PROGRAMME_TYPES_ERROR = 'DELETE_PROGRAMME_TYPES_ERROR';
const CREATE_PROGRAMME_TYPES = 'CREATE_PROGRAMME_TYPES';
const CREATE_PROGRAMME_TYPES_SUCCESS = 'CREATE_PROGRAMME_TYPES_SUCCESS';
const CREATE_PROGRAMME_TYPES_ERROR = 'CREATE_PROGRAMME_TYPES_ERROR';
const UPDATE_ORDER_PROGRAMME_TYPES = 'UPDATE_ORDER_PROGRAMME_TYPES';
const UPDATE_ORDER_PROGRAMME_TYPES_SUCCESS = 'UPDATE_ORDER_PROGRAMME_TYPES_SUCCESS';
const UPDATE_ORDER_PROGRAMME_TYPES_ERROR = 'UPDATE_ORDER_PROGRAMME_TYPES_ERROR';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';

export {
  FETCH_PROGRAMME_TYPES,
  FETCH_PROGRAMME_TYPES_SUCCESS,
  FETCH_PROGRAMME_TYPES_ERROR,
  UPDATE_PROGRAMME_TYPES,
  UPDATE_PROGRAMME_TYPES_SUCCESS,
  UPDATE_PROGRAMME_TYPES_ERROR,
  DELETE_PROGRAMME_TYPES,
  DELETE_PROGRAMME_TYPES_SUCCESS,
  DELETE_PROGRAMME_TYPES_ERROR,
  CREATE_PROGRAMME_TYPES,
  CREATE_PROGRAMME_TYPES_SUCCESS,
  CREATE_PROGRAMME_TYPES_ERROR,
  UPDATE_ORDER_PROGRAMME_TYPES,
  UPDATE_ORDER_PROGRAMME_TYPES_SUCCESS,
  UPDATE_ORDER_PROGRAMME_TYPES_ERROR,
  IS_ADDING_UPDATE,
};
