import * as constants from "./constants";

const initialState = {
  allProjectStatuses: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
};

const projectStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_PROJECT_STATUSES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_PROJECT_STATUSES_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allProjectStatuses: [...state.allProjectStatuses, action.data],
        isAdding: false,
      };
    case constants.CREATE_PROJECT_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.UPDATE_ORDER:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allProjectStatuses: action.data,
      };
    case constants.UPDATE_ORDER_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.FETCH_PROJECT_STATUSES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_PROJECT_STATUSES_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allProjectStatuses: action.data,
        isAdding: false,
      };
    case constants.FETCH_PROJECT_STATUSES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_PROJECT_STATUSES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_PROJECT_STATUSES_SUCCESS:
      const updated = state.allProjectStatuses.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allProjectStatuses: updated,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_PROJECT_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_PROJECT_STATUSES:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_PROJECT_STATUSES_SUCCESS:
      const updatedData = state.allProjectStatuses.filter(
        (el) => el.id !== action.data
      );
      return {
        ...state,
        allProjectStatuses: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_PROJECT_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    default:
      return state;
  }
};

export default projectStatusReducer;
