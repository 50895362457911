const FETCH_LEARNERS_DOCS = 'FETCH_LEARNERS_DOCS';
const FETCH_LEARNERS_DOCS_SUCCESS = 'FETCH_LEARNERS_DOCS_SUCCESS';
const FETCH_LEARNERS_DOCS_ERROR = 'FETCH_LEARNERS_DOCS_ERROR';
const FETCH_LEARNERS_GRAPH = 'FETCH_LEARNERS_GRAPH';
const FETCH_LEARNERS_GRAPH_SUCCESS = 'FETCH_LEARNERS_GRAPH_SUCCESS';
const FETCH_LEARNERS_GRAPH_ERROR = 'FETCH_LEARNERS_GRAPH_ERROR';
const FETCH_ACTIVITIES_SEARCH_DATA = 'FETCH_ACTIVITIES_SEARCH_DATA';
const FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS =
  'FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS';
const FETCH_ACTIVITIES_SEARCH_DATA_ERROR = 'FETCH_ACTIVITIES_SEARCH_DATA_ERROR';
const FETCH_BATCH_UPDATE = 'FETCH_BATCH_UPDATE';
const FETCH_BATCH_UPDATE_SUCCESS = 'FETCH_BATCH_UPDATE_SUCCESS';
const FETCH_BATCH_UPDATE_ERROR = 'FETCH_BATCH_UPDATE_ERROR';

export {
  FETCH_LEARNERS_DOCS,
  FETCH_LEARNERS_DOCS_SUCCESS,
  FETCH_LEARNERS_DOCS_ERROR,
  FETCH_LEARNERS_GRAPH,
  FETCH_LEARNERS_GRAPH_SUCCESS,
  FETCH_LEARNERS_GRAPH_ERROR,
  FETCH_ACTIVITIES_SEARCH_DATA,
  FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS,
  FETCH_ACTIVITIES_SEARCH_DATA_ERROR,
  FETCH_BATCH_UPDATE,
  FETCH_BATCH_UPDATE_SUCCESS,
  FETCH_BATCH_UPDATE_ERROR,
};
