import * as constants from './constants';

export const setInterventionTypeIsEdit = (data) => {
  return {
    type: constants.INTERVENTION_TYPE_IS_EDIT,
    data: data,
  };
};

export const createInterventionType = (data) => {
  return {
    type: constants.CREATE_INTERVENTION_TYPE,
    data: data,
  };
};

export const createInterventionTypeSuccess = (data) => {
  return {
    type: constants.CREATE_INTERVENTION_TYPE_SUCCESS,
    data: data,
  };
};

export const createInterventionTypeError = () => {
  return {
    type: constants.CREATE_INTERVENTION_TYPE_ERROR,
  };
};

export const fetchAllInterventionTypes = (data) => {
  return {
    type: constants.FETCH_ALL_INTERVENTION_TYPES,
    data,
  };
};

export const fetchAllInterventionTypesSuccess = (data) => {
  return {
    type: constants.FETCH_ALL_INTERVENTION_TYPES_SUCCESS,
    data,
  };
};
export const fetchAllInterventionTypesError = (data) => {
  return {
    type: constants.FETCH_ALL_INTERVENTION_TYPES_ERROR,
    data,
  };
};
export const updateInterventionTypes = (data) => {
  return {
    type: constants.UPDATE_INTERVENTION_TYPE,
    data: data,
  };
};

export const updateInterventionTypesSuccess = (data) => {
  return {
    type: constants.UPDATE_INTERVENTION_TYPE_SUCCESS,
    data: data,
  };
};

export const updateInterventionTypesError = () => {
  return {
    type: constants.UPDATE_INTERVENTION_TYPE_ERROR,
  };
};
export const templateCreationCleanupFunc = () => {
  return {
    type: constants.INTERVENTION_TYPE_CREATION_CLEANUP_FUNC,
  };
};
export const deleteInterventionType = (data) => {
  return {
    type: constants.DELETE_INTERVENTION_TYPE,
    data,
  };
};

export const deleteInterventionTypeSuccess = (data) => {
  return {
    type: constants.DELETE_INTERVENTION_TYPE_SUCCESS,
    data,
  };
};

export const deleteInterventionTypeError = (data) => {
  return {
    type: constants.DELETE_INTERVENTION_TYPE_ERROR,
    data,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};

export const fetchInterventionType = (data) => {
  return {
    type: constants.FETCH_INTERVENTION_TYPE,
    data,
  };
};

export const fetchInterventionTypeSuccess = (data) => {
  return {
    type: constants.FETCH_INTERVENTION_TYPE_SUCCESS,
    data,
  };
};

export const fetchInterventionTypeError = () => {
  return {
    type: constants.FETCH_INTERVENTION_TYPE_ERROR,
  };
};
