import * as constants from "./constants";

export const fetchImsDistribution = (data) => {
  return {
    type: constants.FETCH_IMS_DISTRIBUTION,
    data,
  };
};

export const fetchImsDistributionSuccess = (data) => {
  return {
    type: constants.FETCH_IMS_DISTRIBUTION_SUCCESS,
    data: data,
  };
};

export const fetchImsDistributionError = (data) => {
  return {
    type: constants.FETCH_IMS_DISTRIBUTION_ERROR,
    data: data,
  };
};

export const createImsDistribution = (data) => {
  return {
    type: constants.CREATE_IMS_DISTRIBUTION,
    data: data,
  };
};

export const createImsDistributionSuccess = (data) => {
  return {
    type: constants.CREATE_IMS_DISTRIBUTION_SUCCESS,
    data: data,
  };
};

export const createImsDistributionError = () => {
  return {
    type: constants.CREATE_IMS_DISTRIBUTION_ERROR,
  };
};

export const updateImsDistribution = (data) => {
  return {
    type: constants.UPDATE_IMS_DISTRIBUTION,
    data: data,
  };
};

export const updateImsDistributionSuccess = (data) => {
  return {
    type: constants.UPDATE_IMS_DISTRIBUTION_SUCCESS,
    data: data,
  };
};

export const updateImsDistributionError = () => {
  return {
    type: constants.UPDATE_IMS_DISTRIBUTION_ERROR,
  };
};

export const deleteImsDistribution = (data) => {
  return {
    type: constants.DELETE_IMS_DISTRIBUTION,
    data: data,
  };
};

export const deleteImsDistributionSuccess = (data) => {
  return {
    type: constants.DELETE_IMS_DISTRIBUTION_SUCCESS,
    data: data,
  };
};

export const deleteImsDistributionError = () => {
  return {
    type: constants.DELETE_IMS_DISTRIBUTION_ERROR,
  };
};

export const deleteImsDistributionAttach = (data) => {
  return {
    type: constants.DELETE_IMS_DISTRIBUTION_ATTACH,
    data: data,
  };
};

export const deleteImsDistributionAttachSuccess = (data) => {
  return {
    type: constants.DELETE_IMS_DISTRIBUTION_ATTACH_SUCCESS,
    data: data,
  };
};

export const deleteImsDistributionAttachError = () => {
  return {
    type: constants.DELETE_IMS_DISTRIBUTION_ATTACH_ERROR,
  };
};

export const updateImsDistributionIsAddIsUpdate = (data) => {
  return {
    type: constants.UPDATE_IMS_DISTRIBUTION_ISADD_ISUPDATE,
    data,
  };
};

export const addImsDistributionttach = (data) => {
  return {
    type: constants.ADD_IMS_DISTRIBUTION_ATTACH,
    data: data,
  };
};
