const FETCH_NPO_REGIONS = 'FETCH_NPO_REGIONS';
const FETCH_NPO_REGIONS_SUCCESS = 'FETCH_NPO_REGIONS_SUCCESS';
const FETCH_NPO_REGIONS_ERROR = 'FETCH_NPO_REGIONS_ERROR';
const UPDATE_NPO_REGIONS = 'UPDATE_NPO_REGIONS';
const UPDATE_NPO_REGIONS_SUCCESS = 'UPDATE_NPO_REGIONS_SUCCESS';
const UPDATE_NPO_REGIONS_ERROR = 'UPDATE_NPO_REGIONS_ERROR';
const DELETE_NPO_REGIONS = 'DELETE_NPO_REGIONS';
const DELETE_NPO_REGIONS_SUCCESS = 'DELETE_NPO_REGIONS_SUCCESS';
const DELETE_NPO_REGIONS_ERROR = 'DELETE_NPO_REGIONS_ERROR';
const CREATE_NPO_REGIONS = 'CREATE_NPO_REGIONS';
const CREATE_NPO_REGIONS_SUCCESS = 'CREATE_NPO_REGIONS_SUCCESS';
const CREATE_NPO_REGIONS_ERROR = 'CREATE_NPO_REGIONS_ERROR';
const UPDATE_ORDER_NPO_REGIONS = 'UPDATE_ORDER_NPO_REGIONS';
const UPDATE_ORDER_NPO_REGIONS_SUCCESS = 'UPDATE_ORDER_NPO_REGIONS_SUCCESS';
const UPDATE_ORDER_NPO_REGIONS_ERROR = 'UPDATE_ORDER_NPO_REGIONS_ERROR';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';

export {
  FETCH_NPO_REGIONS,
  FETCH_NPO_REGIONS_SUCCESS,
  FETCH_NPO_REGIONS_ERROR,
  UPDATE_NPO_REGIONS,
  UPDATE_NPO_REGIONS_SUCCESS,
  UPDATE_NPO_REGIONS_ERROR,
  DELETE_NPO_REGIONS,
  DELETE_NPO_REGIONS_SUCCESS,
  DELETE_NPO_REGIONS_ERROR,
  CREATE_NPO_REGIONS,
  CREATE_NPO_REGIONS_SUCCESS,
  CREATE_NPO_REGIONS_ERROR,
  UPDATE_ORDER_NPO_REGIONS,
  UPDATE_ORDER_NPO_REGIONS_SUCCESS,
  UPDATE_ORDER_NPO_REGIONS_ERROR,
  IS_ADDING_UPDATE,
};
