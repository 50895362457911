const FETCH_IMS_DOCS = 'FETCH_IMS_DOCS';
const FETCH_IMS_DOCS_SUCCESS = 'FETCH_IMS_DOCS_SUCCESS';
const FETCH_IMS_DOCS_ERROR = 'FETCH_IMS_DOCS_ERROR';
const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
const FETCH_ACTIVITIES_ERROR = 'FETCH_ACTIVITIES_ERROR';
const FETCH_ACTIVITIES_GRAPH_DATA = 'FETCH_ACTIVITIES_GRAPH_DATA';
const FETCH_ACTIVITIES_GRAPH_DATA_SUCCESS =
  'FETCH_ACTIVITIES_GRAPH_DATA_SUCCESS';
const FETCH_ACTIVITIES_GRAPH_DATA_ERROR = 'FETCH_ACTIVITIES_GRAPH_DATA_ERROR';
const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
const UPDATE_ACTIVITY_ERROR = 'UPDATE_ACTIVITY_ERROR';
const FETCH_ACTIVITIES_SEARCH_DATA = 'FETCH_ACTIVITIES_SEARCH_DATA';
const FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS =
  'FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS';
const FETCH_ACTIVITIES_SEARCH_DATA_ERROR = 'FETCH_ACTIVITIES_SEARCH_DATA_ERROR';
const DELETE_IMS_DOC = 'DELETE_IMS_DOC';
const DELETE_IMS_DOC_SUCCESS = 'DELETE_IMS_DOC_SUCCESS';
const DELETE_IMS_DOC_ERROR = 'DELETE_IMS_DOC_ERROR';
const CREATE_IMS_DOC = 'CREATE_IMS_DOC';
const CREATE_IMS_DOC_SUCCESS = 'CREATE_IMS_DOC_SUCCESS';
const CREATE_IMS_DOC_ERROR = 'CREATE_IMS_DOC_ERROR';
const DELETE_IMS_DOC_ATTACH = 'DELETE_IMS_DOC_ATTACH';
const DELETE_IMS_DOC_ATTACH_SUCCESS = 'DELETE_IMS_DOC_ATTACH_SUCCESS';
const DELETE_IMS_DOC_ATTACH_ERROR = 'DELETE_IMS_DOC_ATTACH_ERROR';
const UPDATE_ISADD_ISUPDATE = 'UPDATE_ISADD_ISUPDATE';
const ADD_IMS_DOC_ATTACH = 'ADD_IMS_DOC_ATTACH';
const FETCH_BATCH_UPDATE = 'FETCH_BATCH_UPDATE';
const FETCH_BATCH_UPDATE_SUCCESS = 'FETCH_BATCH_UPDATE_SUCCESS';
const FETCH_BATCH_UPDATE_ERROR = 'FETCH_BATCH_UPDATE_ERROR';
const SET_PILLARS_DATA = 'SET_PILLARS_DATA';
export {
  FETCH_IMS_DOCS,
  FETCH_IMS_DOCS_SUCCESS,
  FETCH_IMS_DOCS_ERROR,
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_ACTIVITIES_ERROR,
  FETCH_ACTIVITIES_GRAPH_DATA,
  FETCH_ACTIVITIES_GRAPH_DATA_SUCCESS,
  FETCH_ACTIVITIES_GRAPH_DATA_ERROR,
  FETCH_ACTIVITIES_SEARCH_DATA,
  FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS,
  FETCH_ACTIVITIES_SEARCH_DATA_ERROR,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_ERROR,
  DELETE_IMS_DOC,
  DELETE_IMS_DOC_SUCCESS,
  DELETE_IMS_DOC_ERROR,
  CREATE_IMS_DOC,
  CREATE_IMS_DOC_SUCCESS,
  CREATE_IMS_DOC_ERROR,
  DELETE_IMS_DOC_ATTACH,
  DELETE_IMS_DOC_ATTACH_SUCCESS,
  DELETE_IMS_DOC_ATTACH_ERROR,
  UPDATE_ISADD_ISUPDATE,
  ADD_IMS_DOC_ATTACH,
  FETCH_BATCH_UPDATE,
  FETCH_BATCH_UPDATE_SUCCESS,
  FETCH_BATCH_UPDATE_ERROR,
  SET_PILLARS_DATA
};
