import * as constants from './constants';

const initialState = {
  allProgrammeTypes: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
};

const siteTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_PROGRAMME_TYPES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_PROGRAMME_TYPES_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allProgrammeTypes: [...state.allProgrammeTypes, action.data],
        isAdding: false,
      };
    case constants.CREATE_PROGRAMME_TYPES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.UPDATE_ORDER_PROGRAMME_TYPES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.UPDATE_ORDER_PROGRAMME_TYPES_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allProgrammeTypes: action.data,
      };
    case constants.UPDATE_ORDER_PROGRAMME_TYPES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.FETCH_PROGRAMME_TYPES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_PROGRAMME_TYPES_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allProgrammeTypes: action.data,
        isAdding: false,
      };
    case constants.FETCH_PROGRAMME_TYPES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_PROGRAMME_TYPES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_PROGRAMME_TYPES_SUCCESS:
      const updated = state.allProgrammeTypes.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allProgrammeTypes: updated,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_PROGRAMME_TYPES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_PROGRAMME_TYPES:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_PROGRAMME_TYPES_SUCCESS:
      const updatedData = state.allProgrammeTypes.filter(
        (el) => el.id !== action.data
      );
      return {
        ...state,
        allProgrammeTypes: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_PROGRAMME_TYPES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    default:
      return state;
  }
};

export default siteTypesReducer;
