import { colorsArray } from "../../Sites/utils";
import {
  sortClients,
  sortContactPerson,
  sortSalesRep,
  sortSoNumber,
} from "../../Sales/utils";
import * as constants from "./constants";

const initialState = {
  firstLevelData: [],
  fetchLoading: false,
  updateLoading: false,
  commentLoading: false,
  fetchGraphLoading: false,
  attachmentLoading: false,
  totalRecords: 0,
  utils: {
    formats: [],
    processing_steps: [],
  },
  salesRepsOptions: [],
  contactPersonsOptions: [],
  clientOptions: [],
  formatOptions: [],
  soNumbersOptions: [],
  statusOptions: [],
  searchUtilsLoading: false,
  graphData: {
    clientOrderedAndCompleted: [],
    clientGraphData: [],
    colorsObjArr: [],
    operatorsOrderedAndCompleted: [],
    operatorGraphData: [],
    operatorsColorsObjArr: [],
  },
};

const productionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PROD_FIRST_LEVEL:
      return {
        ...state,
        fetchLoading: true,
      };
    case constants.FETCH_PROD_FIRST_LEVEL_SUCCESS:
      const { orders, total_orders, formats, processing_steps } = action.data;
      let updatedOrders = orders.map((order) => ({
        ...order,
        id: order.soid,
        key: order.soid,
      }));

      // let sortedSteps = processing_steps.sort();

      //                      sortedSteps.map
      let updatedSteps = processing_steps.map((el) => ({
        label: el,
        value: el,
      }));

      return {
        ...state,
        fetchLoading: false,
        firstLevelData: updatedOrders,
        totalRecords: total_orders,
        formats: formats.sort(),
        processing_steps: updatedSteps,
      };
    case constants.FETCH_PROD_FIRST_LEVEL_ERROR:
      return {
        ...state,
        fetchLoading: false,
      };

    case constants.FIRST_LEVEL_ADD_COMMENT:
      return {
        ...state,
        commentLoading: true,
      };
    case constants.FIRST_LEVEL_ADD_COMMENT_SUCCESS:
      const { soid } = action.data;
      let updatedData = state.firstLevelData.map((el) =>
        el.soid === soid ? { ...el, fetch_comment: action.data } : el
      );
      return {
        ...state,
        commentLoading: false,
        firstLevelData: updatedData,
      };
    case constants.FIRST_LEVEL_ADD_COMMENT_ERROR:
      return {
        ...state,
        commentLoading: false,
      };

    case constants.ADD_PRODUCTION_ATTACH:
      return {
        ...state,
        attachmentLoading: true,
      };
    case constants.ADD_PRODUCTION_ATTACH_SUCCESS:
      const { fetch_attachments } = action.data;
      let updatedAttachData = state.firstLevelData.map((el) =>
        el.soid === action.data.soid ? { ...el, fetch_attachments } : el
      );
      return {
        ...state,
        attachmentLoading: false,
        firstLevelData: updatedAttachData,
      };
    case constants.ADD_PRODUCTION_ATTACH_ERROR:
      return {
        ...state,
        attachmentLoading: false,
      };

    case constants.DELETE_PROD_ATTACH:
      return {
        ...state,
      };
    case constants.DELETE_PROD_ATTACH_SUCCESS:
      const {
        imgItem: { id },
      } = action.data;

      let updatedAttachmentsData = [];

      state.firstLevelData.map((el) => {
        if (el.id === action.data.soid) {
          let fetch_attachments = el.fetch_attachments.filter(
            (img) => img.key !== id
          );
          updatedAttachmentsData.push({ ...el, fetch_attachments });
        } else {
          updatedAttachmentsData.push(el);
        }
      });

      return {
        ...state,
        firstLevelData: updatedAttachmentsData,
      };
    case constants.DELETE_PROD_ATTACH_ERROR:
      return {
        ...state,
      };

    case constants.FETCH_PROD_SEARCH_UTILS:
      return {
        ...state,
        searchUtilsLoading: true,
      };
    case constants.FETCH_PROD_SEARCH_UTILS_SUCCESS:
      const { clients, sales_reps, so_numbers, order_status, order_contacts } =
        action.data;

      const clientsSorted = sortClients(clients);
      const salesRepSorted = sortSalesRep(sales_reps);
      const soNumberSorted = sortSoNumber(so_numbers);
      const contactPersonSorted = sortContactPerson(order_contacts);

      let clientOptions = clientsSorted.map((el) => {
        const { company, cusid } = el;
        return { value: cusid, label: company };
      });

      let salesRepsOptions = salesRepSorted.map((el) => {
        const { listname, staffid } = el;
        return { value: staffid, label: listname };
      });

      let soNumbersOptions = soNumberSorted.map((el) => {
        const { soid } = el;
        return { value: soid, label: soid };
      });

      const formatOptions = action.data.formats.map((el) => ({
        value: el.key,
        label: el.name,
      }));

      const statusOptions = order_status.map((el) => ({
        value: el.key,
        label: el.name,
      }));

      let contactPersonsOptions = contactPersonSorted.map((el) => {
        const { listname, cuscontactid } = el;
        return { value: cuscontactid, label: listname };
      });

      // let formatOptions = formatsSorted.map((el) => {
      //   return { value: el, label: el };
      // });

      return {
        ...state,
        clientOptions,
        salesRepsOptions,
        soNumbersOptions,
        formatOptions,
        statusOptions,
        contactPersonsOptions,
        searchUtilsLoading: false,
      };
    case constants.FETCH_PROD_SEARCH_UTILS_ERROR:
      return {
        ...state,
        searchUtilsLoading: false,
      };

    case constants.FETCH_PROD_GRAPH_DATA:
      return {
        ...state,
        fetchGraphLoading: true,
      };
    case constants.FETCH_PROD_GRAPH_DATA_SUCCESS:
      const {
        clients_formats,
        format_hash,
        operator_format_hash,
        operator_formats,
      } = action.data;

      let formatGraphData = [];
      let clientGraphData = [];
      let operatorFormatGraphData = [];
      let operatorGraphData = [];

      Object.keys(format_hash).forEach((key, index) => {
        formatGraphData.push({
          ...format_hash[key],
          label: key,
          id: index + 1,
        });
      });

      let keysArray = [];
      Object.keys(clients_formats).forEach((key, i) => {
        clientGraphData.push({
          ...clients_formats[key],
          id: key,
        });

        Object.keys(clients_formats[key]).forEach((key2, i) => {
          keysArray.push(key2);
        });
      });

      let unique = [...new Set(keysArray)];

      const result = unique.map((el, i) => ({
        key: el,
        color: colorsArray[i + 1],
      }));

      Object.keys(operator_format_hash).forEach((key, index) => {
        operatorFormatGraphData.push({
          ...operator_format_hash[key],
          label: key,
          id: index + 1,
        });
      });

      let operatorKeysArray = [];
      Object.keys(operator_formats).forEach((key, i) => {
        operatorGraphData.push({
          ...operator_formats[key],
          id: key,
        });

        Object.keys(operator_formats[key]).forEach((key2, i) => {
          operatorKeysArray.push(key2);
        });
      });

      let operatorUnique = [...new Set(operatorKeysArray)];

      const operatorResult = operatorUnique.map((el, i) => ({
        key: el,
        color: colorsArray[i + 1],
      }));

      return {
        ...state,
        fetchGraphLoading: false,
        graphData: {
          clientOrderedAndCompleted: formatGraphData,
          clientGraphData,
          colorsObjArr: result,
          operatorsOrderedAndCompleted: operatorFormatGraphData,
          operatorGraphData,
          operatorsColorsObjArr: operatorResult,
        },
      };
    case constants.FETCH_PROD_GRAPH_DATA_ERROR:
      return {
        ...state,
        fetchGraphLoading: false,
      };

    default:
      return state;
  }
};

export default productionReducer;
