import * as constants from "./constants";

export const fetchProjectDistribution = (data) => {
  return {
    type: constants.FETCH_PROJECT_DISTRIBUTION,
    data,
  };
};

export const fetchProjectDistributionSuccess = (data) => {
  return {
    type: constants.FETCH_PROJECT_DISTRIBUTION_SUCCESS,
    data: data,
  };
};

export const fetchProjectDistributionError = (data) => {
  return {
    type: constants.FETCH_PROJECT_DISTRIBUTION_ERROR,
    data: data,
  };
};

export const createProjectDistribution = (data) => {
  return {
    type: constants.CREATE_PROJECT_DISTRIBUTION,
    data: data,
  };
};

export const createProjectDistributionSuccess = (data) => {
  return {
    type: constants.CREATE_PROJECT_DISTRIBUTION_SUCCESS,
    data: data,
  };
};

export const createProjectDistributionError = () => {
  return {
    type: constants.CREATE_PROJECT_DISTRIBUTION_ERROR,
  };
};

export const updateProjectDistribution = (data) => {
  return {
    type: constants.UPDATE_PROJECT_DISTRIBUTION,
    data: data,
  };
};

export const updateProjectDistributionSuccess = (data) => {
  return {
    type: constants.UPDATE_PROJECT_DISTRIBUTION_SUCCESS,
    data: data,
  };
};

export const updateProjectDistributionError = () => {
  return {
    type: constants.UPDATE_PROJECT_DISTRIBUTION_ERROR,
  };
};

export const deleteProjectDistribution = (data) => {
  return {
    type: constants.DELETE_PROJECT_DISTRIBUTION,
    data: data,
  };
};

export const deleteProjectDistributionSuccess = (data) => {
  return {
    type: constants.DELETE_PROJECT_DISTRIBUTION_SUCCESS,
    data: data,
  };
};

export const deleteProjectDistributionError = () => {
  return {
    type: constants.DELETE_PROJECT_DISTRIBUTION_ERROR,
  };
};

export const deleteProjectDistributionAttach = (data) => {
  return {
    type: constants.DELETE_PROJECT_DISTRIBUTION_ATTACH,
    data: data,
  };
};

export const deleteProjectDistributionAttachSuccess = (data) => {
  return {
    type: constants.DELETE_PROJECT_DISTRIBUTION_ATTACH_SUCCESS,
    data: data,
  };
};

export const deleteProjectDistributionAttachError = () => {
  return {
    type: constants.DELETE_PROJECT_DISTRIBUTION_ATTACH_ERROR,
  };
};

export const updateProjectDistributionIsAddIsUpdate = (data) => {
  return {
    type: constants.UPDATE_PROJECT_DISTRIBUTION_ISADD_ISUPDATE,
    data,
  };
};

export const addProjectDistributionttach = (data) => {
  return {
    type: constants.ADD_PROJECT_DISTRIBUTION_ATTACH,
    data: data,
  };
};
