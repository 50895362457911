import * as constants from './constants';

export const fetchLearnerTypes = (data) => {
  return {
    type: constants.FETCH_LEARNER_TYPES,
    data,
  };
};

export const fetchLearnerTypesSuccess = (data) => {
  return {
    type: constants.FETCH_LEARNER_TYPES_SUCCESS,
    data: data,
  };
};

export const fetchLearnerTypesError = (data) => {
  return {
    type: constants.FETCH_LEARNER_TYPES_ERROR,
    data: data,
  };
};

export const updateLearnerTypes = (data) => {
  return {
    type: constants.UPDATE_LEARNER_TYPES,
    data: data,
  };
};

export const updateLearnerTypesSuccess = (data) => {
  return {
    type: constants.UPDATE_LEARNER_TYPES_SUCCESS,
    data: data,
  };
};

export const updateLearnerTypesError = () => {
  return {
    type: constants.UPDATE_LEARNER_TYPES_ERROR,
  };
};

export const updateLearnerTypesOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_LEARNER_TYPES,
    data: data,
  };
};

export const updateLearnerTypesOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_LEARNER_TYPES_SUCCESS,
    data: data,
  };
};

export const updateLearnerTypesOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_LEARNER_TYPES_ERROR,
  };
};

export const deleteLearnerTypes = (data) => {
  return {
    type: constants.DELETE_LEARNER_TYPES,
    data: data,
  };
};

export const deleteLearnerTypesSuccess = (data) => {
  return {
    type: constants.DELETE_LEARNER_TYPES_SUCCESS,
    data: data,
  };
};

export const deleteLearnerTypesError = () => {
  return {
    type: constants.DELETE_LEARNER_TYPES_ERROR,
  };
};

export const createLearnerTypes = (data, id) => {
  return {
    type: constants.CREATE_LEARNER_TYPES,
    data: data,
  };
};

export const createLearnerTypesSuccess = (data) => {
  return {
    type: constants.CREATE_LEARNER_TYPES_SUCCESS,
    data: data,
  };
};

export const createLearnerTypesError = (id) => {
  return {
    type: constants.CREATE_LEARNER_TYPES_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
