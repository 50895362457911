const FETCH_PROJECT_REVISIONS = "FETCH_PROJECT_REVISIONS";
const FETCH_PROJECT_REVISIONS_SUCCESS = "FETCH_PROJECT_REVISIONS_SUCCESS";
const FETCH_PROJECT_REVISIONS_ERROR = "FETCH_PROJECT_REVISIONS_ERROR";
const UPDATE_PROJECT_REVISIONS = "UPDATE_PROJECT_REVISIONS";
const UPDATE_PROJECT_REVISIONS_SUCCESS = "UPDATE_PROJECT_REVISIONS_SUCCESS";
const UPDATE_PROJECT_REVISIONS_ERROR = "UPDATE_PROJECT_REVISIONS_ERROR";
// const FETCH_PROJECT_REVISIONS_SEARCH_DATA = "FETCH_PROJECT_REVISIONS_SEARCH_DATA";
// const FETCH_PROJECT_REVISIONS_SEARCH_DATA_SUCCESS =
//   "FETCH_PROJECT_REVISIONS_SEARCH_DATA_SUCCESS";
// const FETCH_PROJECT_REVISIONS_SEARCH_DATA_ERROR = "FETCH_PROJECT_REVISIONS_SEARCH_DATA_ERROR";
const DELETE_PROJECT_REVISIONS = "DELETE_PROJECT_REVISIONS";
const DELETE_PROJECT_REVISIONS_SUCCESS = "DELETE_PROJECT_REVISIONS_SUCCESS";
const DELETE_PROJECT_REVISIONS_ERROR = "DELETE_PROJECT_REVISIONS_ERROR";
const CREATE_PROJECT_REVISIONS = "CREATE_PROJECT_REVISIONS";
const CREATE_PROJECT_REVISIONS_SUCCESS = "CREATE_PROJECT_REVISIONS_SUCCESS";
const CREATE_PROJECT_REVISIONS_ERROR = "CREATE_PROJECT_REVISIONS_ERROR";
const DELETE_PROJECT_REVISIONS_ATTACH = "DELETE_PROJECT_REVISIONS_ATTACH";
const DELETE_PROJECT_REVISIONS_ATTACH_SUCCESS =
  "DELETE_PROJECT_REVISIONS_ATTACH_SUCCESS";
const DELETE_PROJECT_REVISIONS_ATTACH_ERROR =
  "DELETE_PROJECT_REVISIONS_ATTACH_ERROR";
const UPDATE_PROJECT_REVISIONS_ISADD_ISUPDATE =
  "UPDATE_PROJECT_REVISIONS_ISADD_ISUPDATE";
const ADD_PROJECT_REVISIONS_ATTACH = "ADD_PROJECT_REVISIONS_ATTACH";

export {
  FETCH_PROJECT_REVISIONS,
  FETCH_PROJECT_REVISIONS_SUCCESS,
  FETCH_PROJECT_REVISIONS_ERROR,
  UPDATE_PROJECT_REVISIONS,
  UPDATE_PROJECT_REVISIONS_SUCCESS,
  UPDATE_PROJECT_REVISIONS_ERROR,
  DELETE_PROJECT_REVISIONS,
  DELETE_PROJECT_REVISIONS_SUCCESS,
  DELETE_PROJECT_REVISIONS_ERROR,
  CREATE_PROJECT_REVISIONS,
  CREATE_PROJECT_REVISIONS_SUCCESS,
  CREATE_PROJECT_REVISIONS_ERROR,
  DELETE_PROJECT_REVISIONS_ATTACH,
  DELETE_PROJECT_REVISIONS_ATTACH_SUCCESS,
  DELETE_PROJECT_REVISIONS_ATTACH_ERROR,
  UPDATE_PROJECT_REVISIONS_ISADD_ISUPDATE,
  ADD_PROJECT_REVISIONS_ATTACH,
};
