import * as constants from "./constants";

const initialState = {
  thirdLevelData: [],
  fetchLoading: false,
};

const thirdLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PROD_THIRD_LEVEL:
      return {
        ...state,
        fetchLoading: true,
        thirdLevelData: [],
      };
    case constants.FETCH_PROD_THIRD_LEVEL_SUCCESS:
      const { with_steps } = action.data;
      let updated_steps_with = with_steps.map((item) => ({
        ...item,
        key: item.id,
      }));
      return {
        ...state,
        fetchLoading: false,
        thirdLevelData: updated_steps_with,
      };
    case constants.FETCH_PROD_THIRD_LEVEL_ERROR:
      return {
        ...state,
        fetchLoading: false,
      };
    case constants.UPDATE_THIRD_LEVEL_ON_UPDATE_FOURTH_LEVEL:
      const updatedData = state.thirdLevelData.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        thirdLevelData: updatedData,
      };
    default:
      return state;
  }
};

export default thirdLevelReducer;
