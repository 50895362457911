import * as constants from "./constants";

export const fetchDocumentField = (data) => {
  return {
    type: constants.FETCH_DOCUMENT_FIELDS,
    data,
  };
};

export const fetchDocumentFieldSuccess = (data) => {
  return {
    type: constants.FETCH_DOCUMENT_FIELDS_SUCCESS,
    data: data,
  };
};

export const fetchDocumentFieldError = (data) => {
  return {
    type: constants.FETCH_DOCUMENT_FIELDS_ERROR,
    data: data,
  };
};

export const updateDocumentField = (data) => {
  return {
    type: constants.UPDATE_DOCUMENT_FIELDS,
    data: data,
  };
};

export const updateDocumentFieldSuccess = (data) => {
  return {
    type: constants.UPDATE_DOCUMENT_FIELDS_SUCCESS,
    data: data,
  };
};

export const updateDocumentFieldError = () => {
  return {
    type: constants.UPDATE_DOCUMENT_FIELDS_ERROR,
    
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
