import React from 'react';
import { Row, Col, Checkbox } from 'antd';
// import UseSupAdminOrAdminWithUserManagerPermission from "../../../components/useSupAdminOrAdminWithUserManagerPermission";

const TablePermissionsSection = ({
  isProfileCase,
  setData,
  data,
  permissions,
  setPermissions,
  disabledCheckBox,
}) => {
  //   let canEditEveryThing = UseSupAdminOrAdminWithUserManagerPermission();

  const onChangeNotification = (key, checked) => {
    setData({ ...data, [key]: checked });
  };

  const onChange = (checked, label, key, prevData) => {
    // console.log(`checked, label, key, prevData => `, checked, label, key, prevData);

    if (!prevData?.view && checked && ['export', 'delete'].includes(key)) {
      return;
    }

    if (prevData?.add_edit && !checked && key === 'view') {
      return;
    }

    if (key === 'add_edit' && checked) {
      let obj = {
        [label]: {
          ...prevData,
          [key]: checked,
          view: true,
        },
      };
      return setPermissions((prev) => ({
        ...prev,
        ...obj,
      }));
    }

    if (key === 'view' && !checked && ['document_control'].includes(label)) {
      let obj = {
        [label]: {
          ...prevData,
          [key]: checked,
          export: false,
          delete: false,
        },
      };
      return setPermissions((prev) => ({
        ...prev,
        ...obj,
      }));
    }

    if (
      key === 'view' &&
      !checked &&
      ['revisions', 'distribution'].includes(label)
    ) {
      let obj = {
        [label]: {
          ...prevData,
          [key]: checked,
          delete: false,
        },
      };
      return setPermissions((prev) => ({
        ...prev,
        ...obj,
      }));
    }

    let obj = {
      [label]: {
        ...prevData,
        [key]: checked,
      },
    };
    setPermissions((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const handleLabel = (labelText) => {
    return labelText.split('_').join(' ');
  };

  const checkText = (labelText) => {
    return labelText.split('_').join(' / ');
  };

  const upperRow = Object?.entries(permissions)?.map((data) => {
    const [label, checkBoxData] = data;
    // console.log("label, checkBoxData", label, checkBoxData);
    return (
      <Col key={label} className='general-permissions-section upper-row'>
        <div className='label-div'>
          <span className='uppercase-label'>{handleLabel(label)}</span>
        </div>
        {Object?.entries(checkBoxData)?.map((data2) => {
          const [key, value] = data2;
          return (
            <div className='checkbox-div' key={key}>
              <Checkbox
                className={disabledCheckBox ? 'border-less' : ''}
                key={key}
                checked={value}
                disabled={
                  disabledCheckBox ||
                  (!checkBoxData.view && ['export', 'delete'].includes(key))
                }
                onChange={({ target }) =>
                  onChange(target.checked, label, key, checkBoxData)
                }
              >
                {checkText(key)}
              </Checkbox>
            </div>
          );
        })}
      </Col>
    );
  });

  return (
    <Col className='table-permission-section'>
      <Row className='block-row'>
        <Col span={24} className='heading'>
          PERMISSIONS
        </Col>
        {upperRow}
      </Row>
    </Col>
  );
};

export default TablePermissionsSection;
