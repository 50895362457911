import * as constants from "./constant";

export const fetchAllNotifications = (data) => {
  return {
    type: constants.FETCH_NOTIFICATIONS_LIST,
    data,
  };
};

export const fetchAllNotificationsSuccess = (data) => {
  return {
    type: constants.FETCH_NOTIFICATIONS_LIST_SUCCESS,
    data,
  };
};

export const fetchAllNotificationsError = (data) => {
  return {
    type: constants.FETCH_NOTIFICATIONS_LIST_ERROR,
    data,
  };
};

export const fetchUnreadNotifications = (data) => {
  return {
    type: constants.FETCH_NOTIFICATIONS,
    data,
  };
};

export const fetchUnreadNotificationsSuccess = (data) => {
  return {
    type: constants.FETCH_NOTIFICATIONS_SUCCESS,
    data,
  };
};

export const fetchUnreadNotificationsError = (data) => {
  return {
    type: constants.FETCH_NOTIFICATIONS_ERROR,
    data,
  };
};

export const updateNotification = (data) => {
  return {
    type: constants.UPDATE_NOTIFICATION,
    data,
  };
};

export const updateNotificationSuccess = (data) => {
  return {
    type: constants.UPDATE_NOTIFICATION_SUCCESS,
    data,
  };
};

export const updateNotificationError = (data) => {
  return {
    type: constants.UPDATE_NOTIFICATION_ERROR,
    data,
  };
};

export const newNotificationAdded = (data) => {
  return {
    type: constants.NEW_NOTIFICATION_ADDED,
    data,
  };
};
