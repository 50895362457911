import React, { useState } from "react";
import { Button, Checkbox, Col, Row, Select } from "antd";

const { Option } = Select;

const AMultiSelect = (props) => {
  const { selectTags, onSelectChange, data, debouncedCallApi, placeholder } =
    props;
  const [open, setOpen] = useState(false);
  const [prev, setPrev] = useState([]);

  const handleOnClick = () => {
    setTimeout(() => {
      setOpen(false);
    }, 500);
    setPrev(selectTags.sort((a, b) => a - b));
    if (JSON.stringify(prev) === JSON.stringify(selectTags)) {
      setPrev([]);
    } else {
      debouncedCallApi();
    }
  };

  return (
    <div className="" style={{ width: "100%" }}>
      {/* {open && (
        <div className="closee">
          <CloseOutlined
            className="cross-icon"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
          />
        </div>
      )} */}
      <Select
        {...props}
        onClick={(e) => {
          if (!open) {
            setOpen(true);
          }
          e.stopPropagation();
          e.preventDefault();
        }}
        onChange={(e) => {
          onSelectChange(e);
        }}
        getPopupContainer={() =>
          document.getElementsByClassName(
            "ant-layout-content layout-content site-layout-background custom-textarea"
          )[0]
        }
        optionLabelProp="label"
        open={open}
        placeholder={placeholder}
        mode="multiple"
        maxTagCount={0}
        size="small"
        defaultValue={selectTags}
        value={selectTags}
        maxTagPlaceholder={() => `${selectTags.length}`}
        menuItemSelectedIcon=""
        style={{
          width: "100%",
          marginBottom: "1px",
          // minWidth: "140px"
        }}
        listHeight={400}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              <Row justify="space-around" align="middle">
                <Col>
                  <Button className="done-btn" onClick={handleOnClick}>
                    Done
                  </Button>
                </Col>
              </Row>
            </>
          );
        }}
        dropdownClassName={`multi-select-custom ${props?.dropdownClassName}`}
      >
        {data.length > 0 && (
          <Option
            label={"Select All"}
            value={selectTags.length === data.length ? "not-all" : "all"}
            // onClick={(e) => {
            //   console.log("option", e);
            // }}
          >
            <div className="multiselect-checkbox">
              <Checkbox
                checked={selectTags && selectTags.length === data.length}
              >
                <div className="select-all-div">
                  <div>Select All</div>
                </div>
              </Checkbox>
            </div>
          </Option>
        )}

        {data.map((item) => {
          return (
            <Option
              label={item.label}
              value={item.value}
              key={item.value}
              // onClick={(e) => {
              //   console.log("option", e);
              // }}
            >
              <div className="multiselect-checkbox">
                <Checkbox
                  checked={selectTags && selectTags.includes(item.value)}
                  style={{ width: "100%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "13px",
                        color: "#231f20",
                      }}
                    >
                      {item.label}
                    </div>
                  </div>
                </Checkbox>
              </div>
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default AMultiSelect;
