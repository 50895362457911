const FETCH_COACH_STATUSES= "FETCH_COACH_STATUSES";
const FETCH_COACH_STATUSES_SUCCESS = "FETCH_COACH_STATUSES_SUCCESS";
const FETCH_COACH_STATUSES_ERROR = "FETCH_COACH_STATUSES_ERROR";
const UPDATE_COACH_STATUSES = "UPDATE_COACH_STATUSES";
const UPDATE_COACH_STATUSES_SUCCESS = "UPDATE_COACH_STATUSES_SUCCESS";
const UPDATE_COACH_STATUSES_ERROR = "UPDATE_COACH_STATUSES_ERROR";
const DELETE_COACH_STATUSES = "DELETE_COACH_STATUSES";
const DELETE_COACH_STATUSES_SUCCESS = "DELETE_COACH_STATUSES_SUCCESS";
const DELETE_COACH_STATUSES_ERROR = "DELETE_COACH_STATUSES_ERROR";
const CREATE_COACH_STATUSES = "CREATE_COACH_STATUSES";
const CREATE_COACH_STATUSES_SUCCESS = "CREATE_COACH_STATUSES_SUCCESS";
const CREATE_COACH_STATUSES_ERROR = "CREATE_COACH_STATUSES_ERROR";
const UPDATE_ORDER_COACH_STATUS = "UPDATE_ORDER_COACH_STATUS";
const UPDATE_ORDER_COACH_STATUS_SUCCESS = "UPDATE_ORDER_COACH_STATUS_SUCCESS";
const UPDATE_ORDER_COACH_STATUS_ERROR = "UPDATE_ORDER_COACH_STATUS_ERROR";
const IS_ADDING_UPDATE = "IS_ADDING_UPDATE";

export {
  FETCH_COACH_STATUSES,
  FETCH_COACH_STATUSES_SUCCESS,
  FETCH_COACH_STATUSES_ERROR,
  UPDATE_COACH_STATUSES,
  UPDATE_COACH_STATUSES_SUCCESS,
  UPDATE_COACH_STATUSES_ERROR,
  DELETE_COACH_STATUSES,
  DELETE_COACH_STATUSES_SUCCESS,
  DELETE_COACH_STATUSES_ERROR,
  CREATE_COACH_STATUSES,
  CREATE_COACH_STATUSES_SUCCESS,
  CREATE_COACH_STATUSES_ERROR,
  UPDATE_ORDER_COACH_STATUS,
  UPDATE_ORDER_COACH_STATUS_SUCCESS,
  UPDATE_ORDER_COACH_STATUS_ERROR,
  IS_ADDING_UPDATE,
};
