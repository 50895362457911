import * as constants from './constants';

const initialState = {
  docTypeGrpahData: [],
  allDocs: [],
  fetchingLoading: false, // true
  fetchDocsLoading: false, // true
  totalRecords: 0,
  totalAndCompleted: [],
};

const coachesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_COACHES_DOCS:
      return {
        ...state,
        fetchDocsLoading: true,
      };
    case constants.FETCH_COACHES_DOCS_SUCCESS:
      return {
        ...state,
        fetchDocsLoading: false,
        allDocs: action.data.coaches,
        totalRecords: action.data.total_coaches,
      };
    case constants.FETCH_COACHES_DOCS_ERROR:
      return {
        ...state,
        fetchDocsLoading: false,
      };

    case constants.FETCH_ACTIVITIES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_ACTIVITIES_SUCCESS:
      const { completed, total, ...rest } = action.data;

      let totalAndCompleted = [
        {
          label: '',
          id: 1,
          Completed: completed,
          Total: total,
        },
      ];

      let result = [];

      Object.entries(rest).map((el, i) =>
        result.push({
          Completed: el[1].completed_by_template,
          id: i + 1,
          label: el[0],
          Total: el[1].total_by_template,
        })
      );

      return {
        ...state,
        fetchingLoading: false,
        docTypeGrpahData: result,
        totalAndCompleted,
      };
    case constants.FETCH_ACTIVITIES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.FETCH_BATCH_UPDATE_SUCCESS:
      return {
        ...state,
        fetchDocsLoading: false,
        allDocs: action.data.documents,
        totalRecords: action.data.total_documents,
      };

    case constants.FETCH_BATCH_UPDATE_ERROR:
      return {
        ...state,
        fetchDocsLoading: false,
      };

    default:
      return state;
  }
};

export default coachesReducer;
