import * as constants from "./constants";

const initialState = {
  allImsRevisions: [],
  fetchingLoading: false,
  updateLoading: false,
  deleteAttachLoading: false,
  fetchUtilsLoading: false,
  isAddImsRevision: false,
  isUpdateImsRevision: false,
  deletingLoading: false,
};

const imsRevisionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_IMS_REVISIONS:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_IMS_REVISIONS_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allImsRevisions: action.data,
      };
    case constants.FETCH_IMS_REVISIONS_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.CREATE_IMS_REVISIONS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_IMS_REVISIONS_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allImsRevisions: [action.data, ...state.allImsRevisions],
        isAddImsRevision: false,
        isUpdateImsRevision: false,
      };
    case constants.CREATE_IMS_REVISIONS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAddImsRevision: false,
        isUpdateImsRevision: false,
      };
    // case constants.FETCH_ACTIVITIES_SEARCH_DATA:
    //   return {
    //     ...state,
    //     fetchUtilsLoading: true,
    //   };
    // case constants.FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS:
    //   const { activity_types, sales_reps } = action.data;

    //   const sortedActivityTypes = sortActivityTypes(activity_types);
    //   const sortedSalesRep = sortActivitiesSalesRep(sales_reps);

    //   let activityTypesOptions = sortedActivityTypes.map((el) => {
    //     const { activity_type, id } = el;
    //     return { value: id, label: activity_type };
    //   });

    //   let salesRepOptions = sortedSalesRep.map((el) => {
    //     const { username, id } = el;
    //     return { value: id, label: username };
    //   });

    //   return {
    //     ...state,
    //     fetchUtilsLoading: false,
    //     salesRepOptions,
    //     activityTypesOptions,
    //   };
    // case constants.FETCH_ACTIVITIES_SEARCH_DATA_ERROR:
    //   return {
    //     ...state,
    //     fetchUtilsLoading: false,
    //   };

    case constants.UPDATE_IMS_REVISIONS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_IMS_REVISIONS_SUCCESS:
      const updated = state.allImsRevisions.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allImsRevisions: updated,
        updateLoading: false,
        isAddImsRevision: false,
        isUpdateImsRevision: false,
      };
    case constants.UPDATE_IMS_REVISIONS_ERROR:
      return {
        ...state,
        updateLoading: false,
      };
    case constants.DELETE_IMS_REVISIONS:
      return {
        ...state,
        deletingLoading: true,
      };
    case constants.DELETE_IMS_REVISIONS_SUCCESS:
      const updatedAct = state.allImsRevisions.filter(
        (el) => el.id !== action.data.id
      );
      return {
        ...state,
        allImsRevisions: updatedAct,
        deletingLoading: false,
      };
    case constants.DELETE_IMS_REVISIONS_ERROR:
      return {
        ...state,
        deletingLoading: false,
      };
    case constants.DELETE_IMS_REVISIONS_ATTACH:
      return {
        ...state,
        deleteAttachLoading: true,
      };
    case constants.DELETE_IMS_REVISIONS_ATTACH_SUCCESS:
      const {
        recordId,
        imgItem: { id },
      } = action.data;

      let updatedActivities = [];

      state.allImsRevisions.map((el) => {
        if (el.id === recordId) {
          let revision_attachments = el.revision_attachments.filter(
            (img) => img.id != id
          );
          updatedActivities.push({ ...el, revision_attachments });
        } else {
          updatedActivities.push(el);
        }
      });

      return {
        ...state,
        allImsRevisions: updatedActivities,
        deleteAttachLoading: false,
      };
    case constants.UPDATE_IMS_REVISIONS_ISADD_ISUPDATE:
      const { isAddImsRevision, isUpdateImsRevision } = action.data;
      return {
        ...state,
        isAddImsRevision,
        isUpdateImsRevision,
      };
    case constants.DELETE_IMS_REVISIONS_ATTACH_ERROR:
      return {
        ...state,
        deleteAttachLoading: false,
      };
    default:
      return state;
  }
};

export default imsRevisionsReducer;
