const FETCH_IMS_REVISIONS = "FETCH_IMS_REVISIONS";
const FETCH_IMS_REVISIONS_SUCCESS = "FETCH_IMS_REVISIONS_SUCCESS";
const FETCH_IMS_REVISIONS_ERROR = "FETCH_IMS_REVISIONS_ERROR";
const UPDATE_IMS_REVISIONS = "UPDATE_IMS_REVISIONS";
const UPDATE_IMS_REVISIONS_SUCCESS = "UPDATE_IMS_REVISIONS_SUCCESS";
const UPDATE_IMS_REVISIONS_ERROR = "UPDATE_IMS_REVISIONS_ERROR";
// const FETCH_IMS_REVISIONS_SEARCH_DATA = "FETCH_IMS_REVISIONS_SEARCH_DATA";
// const FETCH_IMS_REVISIONS_SEARCH_DATA_SUCCESS =
//   "FETCH_IMS_REVISIONS_SEARCH_DATA_SUCCESS";
// const FETCH_IMS_REVISIONS_SEARCH_DATA_ERROR = "FETCH_IMS_REVISIONS_SEARCH_DATA_ERROR";
const DELETE_IMS_REVISIONS = "DELETE_IMS_REVISIONS";
const DELETE_IMS_REVISIONS_SUCCESS = "DELETE_IMS_REVISIONS_SUCCESS";
const DELETE_IMS_REVISIONS_ERROR = "DELETE_IMS_REVISIONS_ERROR";
const CREATE_IMS_REVISIONS = "CREATE_IMS_REVISIONS";
const CREATE_IMS_REVISIONS_SUCCESS = "CREATE_IMS_REVISIONS_SUCCESS";
const CREATE_IMS_REVISIONS_ERROR = "CREATE_IMS_REVISIONS_ERROR";
const DELETE_IMS_REVISIONS_ATTACH = "DELETE_IMS_REVISIONS_ATTACH";
const DELETE_IMS_REVISIONS_ATTACH_SUCCESS =
  "DELETE_IMS_REVISIONS_ATTACH_SUCCESS";
const DELETE_IMS_REVISIONS_ATTACH_ERROR = "DELETE_IMS_REVISIONS_ATTACH_ERROR";
const UPDATE_IMS_REVISIONS_ISADD_ISUPDATE =
  "UPDATE_IMS_REVISIONS_ISADD_ISUPDATE";
const ADD_IMS_REVISIONS_ATTACH = "ADD_IMS_REVISIONS_ATTACH";

export {
  FETCH_IMS_REVISIONS,
  FETCH_IMS_REVISIONS_SUCCESS,
  FETCH_IMS_REVISIONS_ERROR,
  UPDATE_IMS_REVISIONS,
  UPDATE_IMS_REVISIONS_SUCCESS,
  UPDATE_IMS_REVISIONS_ERROR,
  DELETE_IMS_REVISIONS,
  DELETE_IMS_REVISIONS_SUCCESS,
  DELETE_IMS_REVISIONS_ERROR,
  CREATE_IMS_REVISIONS,
  CREATE_IMS_REVISIONS_SUCCESS,
  CREATE_IMS_REVISIONS_ERROR,
  DELETE_IMS_REVISIONS_ATTACH,
  DELETE_IMS_REVISIONS_ATTACH_SUCCESS,
  DELETE_IMS_REVISIONS_ATTACH_ERROR,
  UPDATE_IMS_REVISIONS_ISADD_ISUPDATE,
  ADD_IMS_REVISIONS_ATTACH,
};
