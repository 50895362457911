const FETCH_DEPARTMENTS= "FETCH_DEPARTMENTS";
const FETCH_DEPARTMENTS_SUCCESS = "FETCH_DEPARTMENTS_SUCCESS";
const FETCH_DEPARTMENTS_ERROR = "FETCH_DEPARTMENTS_ERROR";
const UPDATE_DEPARTMENTS = "UPDATE_DEPARTMENTS";
const UPDATE_DEPARTMENTS_SUCCESS = "UPDATE_DEPARTMENTS_SUCCESS";
const UPDATE_DEPARTMENTS_ERROR = "UPDATE_DEPARTMENTS_ERROR";
const DELETE_DEPARTMENTS = "DELETE_DEPARTMENTS";
const DELETE_DEPARTMENTS_SUCCESS = "DELETE_DEPARTMENTS_SUCCESS";
const DELETE_DEPARTMENTS_ERROR = "DELETE_DEPARTMENTS_ERROR";
const CREATE_DEPARTMENTS = "CREATE_DEPARTMENTSE";
const CREATE_DEPARTMENTS_SUCCESS = "CREATE_DEPARTMENTS_SUCCESS";
const CREATE_DEPARTMENTS_ERROR = "CREATE_DEPARTMENTS_ERROR";
const IS_ADDING_UPDATE = "IS_ADDING_UPDATE";

export {
  FETCH_DEPARTMENTS,
  FETCH_DEPARTMENTS_SUCCESS,
  FETCH_DEPARTMENTS_ERROR,
  UPDATE_DEPARTMENTS,
  UPDATE_DEPARTMENTS_SUCCESS,
  UPDATE_DEPARTMENTS_ERROR,
  DELETE_DEPARTMENTS,
  DELETE_DEPARTMENTS_SUCCESS,
  DELETE_DEPARTMENTS_ERROR,
  CREATE_DEPARTMENTS,
  CREATE_DEPARTMENTS_SUCCESS,
  CREATE_DEPARTMENTS_ERROR,
  IS_ADDING_UPDATE,
};
