import * as constants from "./constants";

export const fetchProjectDocs = (data) => {
  return {
    type: constants.FETCH_PROJECT_DOCS,
    data: data,
  };
};

export const fetchProjectDocsSuccess = (data) => {
  return {
    type: constants.FETCH_PROJECT_DOCS_SUCCESS,
    data: data,
  };
};

export const fetchProjectDocsError = () => {
  return {
    type: constants.FETCH_PROJECT_DOCS_ERROR,
  };
};

export const fetchProjectActivities = (data) => {
  return {
    type: constants.FETCH_PROJECT_ACTIVITIES,
    data,
  };
};

export const fetchProjectActivitiesSuccess = (data) => {
  return {
    type: constants.FETCH_PROJECT_ACTIVITIES_SUCCESS,
    data: data,
  };
};

export const fetchProjectActivitiesSearchUtils = (data) => {
  return {
    type: constants.FETCH_PROJECT_ACTIVITIES_SEARCH_DATA,
    data,
  };
};

export const fetchProjectActivitiesSearchUtilsSuccess = (data) => {
  return {
    type: constants.FETCH_PROJECT_ACTIVITIES_SEARCH_DATA_SUCCESS,
    data: data,
  };
};

export const fetchProjectActivitiesSearchUtilsError = (data) => {
  return {
    type: constants.FETCH_PROJECT_ACTIVITIES_SEARCH_DATA_ERROR,
    data,
  };
};
export const updateProjectActivity = (data) => {
  return {
    type: constants.UPDATE_PROJECT_ACTIVITY,
    data: data,
  };
};

export const updateProjectActivitySuccess = (data) => {
  return {
    type: constants.UPDATE_PROJECT_ACTIVITY_SUCCESS,
    data: data,
  };
};

export const updateProjectActivityError = () => {
  return {
    type: constants.UPDATE_PROJECT_ACTIVITY_ERROR,
  };
};

export const deleteProjectDoc = (data) => {
  return {
    type: constants.DELETE_PROJECT_DOC,
    data: data,
  };
};

export const deleteProjectDocSuccess = (data) => {
  return {
    type: constants.DELETE_PROJECT_DOC_SUCCESS,
    data: data,
  };
};

export const deleteProjectDocError = () => {
  return {
    type: constants.DELETE_PROJECT_DOC_ERROR,
  };
};

export const createProjectDoc = (data) => {
  return {
    type: constants.CREATE_PROJECT_DOC,
    data: data,
  };
};

export const createProjectDocSuccess = (data) => {
  return {
    type: constants.CREATE_PROJECT_DOC_SUCCESS,
    data: data,
  };
};

export const createProjectDocError = () => {
  return {
    type: constants.CREATE_PROJECT_DOC_ERROR,
  };
};

export const deleteProjectDocAttach = (data) => {
  return {
    type: constants.DELETE_PROJECT_DOC_ATTACH,
    data: data,
  };
};

export const deleteProjectDocAttachSuccess = (data) => {
  return {
    type: constants.DELETE_PROJECT_DOC_ATTACH_SUCCESS,
    data: data,
  };
};

export const deleteProjectDocAttachError = () => {
  return {
    type: constants.DELETE_PROJECT_DOC_ATTACH_ERROR,
  };
};

export const updateProjectIsAddIsUpdate = (data) => {
  return {
    type: constants.UPDATE_PROJECT_ISADD_ISUPDATE,
    data,
  };
};

export const addProjectActivityAttach = (data) => {
  return {
    type: constants.ADD_PROJECT_DOC_ATTACH,
    data: data,
  };
};

export const fetchProjectBatchUpdate = (data) => {
  return {
    type: constants.FETCH_PROJECT_BATCH_UPDATE,
    data: data,
  };
};

export const fetchProjectBatchUpdateSuccess = (data) => {
  return {
    type: constants.FETCH_PROJECT_BATCH_UPDATE_SUCCESS,
    data: data,
  };
};

export const fetchProjectBatchUpdateError = () => {
  return {
    type: constants.FETCH_PROJECT_BATCH_UPDATE_ERROR,
  };
};
