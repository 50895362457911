const FETCH_PROJECT_DISTRIBUTION = "FETCH_PROJECT_DISTRIBUTION";
const FETCH_PROJECT_DISTRIBUTION_SUCCESS = "FETCH_PROJECT_DISTRIBUTION_SUCCESS";
const FETCH_PROJECT_DISTRIBUTION_ERROR = "FETCH_PROJECT_DISTRIBUTION_ERROR";
const UPDATE_PROJECT_DISTRIBUTION = "UPDATE_PROJECT_DISTRIBUTION";
const UPDATE_PROJECT_DISTRIBUTION_SUCCESS =
  "UPDATE_PROJECT_DISTRIBUTION_SUCCESS";
const UPDATE_PROJECT_DISTRIBUTION_ERROR = "UPDATE_PROJECT_DISTRIBUTION_ERROR";
const DELETE_PROJECT_DISTRIBUTION = "DELETE_PROJECT_DISTRIBUTION";
const DELETE_PROJECT_DISTRIBUTION_SUCCESS =
  "DELETE_PROJECT_DISTRIBUTION_SUCCESS";
const DELETE_PROJECT_DISTRIBUTION_ERROR = "DELETE_PROJECT_DISTRIBUTION_ERROR";
const CREATE_PROJECT_DISTRIBUTION = "CREATE_PROJECT_DISTRIBUTION";
const CREATE_PROJECT_DISTRIBUTION_SUCCESS =
  "CREATE_PROJECT_DISTRIBUTION_SUCCESS";
const CREATE_PROJECT_DISTRIBUTION_ERROR = "CREATE_PROJECT_DISTRIBUTION_ERROR";
const DELETE_PROJECT_DISTRIBUTION_ATTACH = "DELETE_PROJECT_DISTRIBUTION_ATTACH";
const DELETE_PROJECT_DISTRIBUTION_ATTACH_SUCCESS =
  "DELETE_PROJECT_DISTRIBUTION_ATTACH_SUCCESS";
const DELETE_PROJECT_DISTRIBUTION_ATTACH_ERROR =
  "DELETE_PROJECT_DISTRIBUTION_ATTACH_ERROR";
const UPDATE_PROJECT_DISTRIBUTION_ISADD_ISUPDATE =
  "UPDATE_PROJECT_DISTRIBUTION_ISADD_ISUPDATE";
const ADD_PROJECT_DISTRIBUTION_ATTACH = "ADD_PROJECT_DISTRIBUTION_ATTACH";

export {
  FETCH_PROJECT_DISTRIBUTION,
  FETCH_PROJECT_DISTRIBUTION_SUCCESS,
  FETCH_PROJECT_DISTRIBUTION_ERROR,
  UPDATE_PROJECT_DISTRIBUTION,
  UPDATE_PROJECT_DISTRIBUTION_SUCCESS,
  UPDATE_PROJECT_DISTRIBUTION_ERROR,
  DELETE_PROJECT_DISTRIBUTION,
  DELETE_PROJECT_DISTRIBUTION_SUCCESS,
  DELETE_PROJECT_DISTRIBUTION_ERROR,
  CREATE_PROJECT_DISTRIBUTION,
  CREATE_PROJECT_DISTRIBUTION_SUCCESS,
  CREATE_PROJECT_DISTRIBUTION_ERROR,
  DELETE_PROJECT_DISTRIBUTION_ATTACH,
  DELETE_PROJECT_DISTRIBUTION_ATTACH_SUCCESS,
  DELETE_PROJECT_DISTRIBUTION_ATTACH_ERROR,
  UPDATE_PROJECT_DISTRIBUTION_ISADD_ISUPDATE,
  ADD_PROJECT_DISTRIBUTION_ATTACH,
};
