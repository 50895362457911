import moment from "moment";
import * as constants from "./constant";

const INITIAL_STATE = {
  allNotifications: [],
  unreadNotifications: 0,
  notificationLoading: false,
};

const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.NEW_NOTIFICATION_ADDED:
      // let updatedList = [action.data, ...state.allNotifications];
      return {
        ...state,
        // allNotifications: updatedList,
        unreadNotifications: state.unreadNotifications + 1,
      };
    case constants.NOTIFICATIONS_LOADING:
      return {
        ...state,
        notificationLoading: action.payload,
      };
    case constants.FETCH_NOTIFICATIONS:
      return {
        ...state,
        // unreadNotifications: action.payload,
      };
    case constants.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        unreadNotifications: action.data,
      };
    case constants.FETCH_NOTIFICATIONS_LIST:
      return {
        ...state,
        notificationLoading: true,
      };
    case constants.FETCH_NOTIFICATIONS_LIST_SUCCESS:
      return {
        ...state,
        allNotifications: action.data,
        notificationLoading: false,
      };
    case constants.UPDATE_NOTIFICATION:
      return {
        ...state,
        // allNotifications: action.payload,
        // unreadNotifications: 0,
      };
    case constants.UPDATE_NOTIFICATION_SUCCESS:
      // const { id, created_at } = action.data;
      // let result = [];
      // const updated = state.allNotifications.map((el) => {
      //   const { date, notifications } = el;

      //   if (moment(date).isSame(moment(created_at).format("YYYY-MM-DD"))) {
      //     let final = notifications.map((ele) =>
      //       ele.id === id ? action.data : ele
      //     );
      //     result(final);
      //     return final;
      //   } else {
      //     result.push(el);
      //     return el;
      //   }

      //   // el.id === action.data?.id ? action.data : el;
      // });
      // console.log("updated", updated);
      // console.log("result", result);

      return {
        ...state,
        // allNotifications: result,
        // unreadNotifications:
        //   state?.unreadNotifications > 0 ? state.unreadNotifications - 1 : 0,
        // notificationLoading: false,
      };
    case constants.NOTIFICATION_HAS_READ:
      return {
        ...state,
        unreadNotifications:
          state?.unreadNotifications > 0 ? state.unreadNotifications - 1 : 0,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
