import * as constants from './constants';

export const fetchImsDocs = (data) => {
  return {
    type: constants.FETCH_IMS_DOCS,
    data: data,
  };
};

export const fetchImsDocsSuccess = (data) => {
  return {
    type: constants.FETCH_IMS_DOCS_SUCCESS,
    data: data,
  };
};

export const fetchImsDocsError = () => {
  return {
    type: constants.FETCH_IMS_DOCS_ERROR,
  };
};

export const fetchActivities = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES,
    data,
  };
};

export const fetchActivitiesSuccess = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SUCCESS,
    data: data,
  };
};

export const fetchActivitiesSearchUtils = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA,
    data,
  };
};

export const fetchActivitiesSearchUtilsSuccess = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS,
    data: data,
  };
};

export const fetchActivitiesSearchUtilsError = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA_ERROR,
    data,
  };
};
export const updateActivity = (data) => {
  return {
    type: constants.UPDATE_ACTIVITY,
    data: data,
  };
};

export const updateActivitySuccess = (data) => {
  return {
    type: constants.UPDATE_ACTIVITY_SUCCESS,
    data: data,
  };
};

export const updateActivityError = () => {
  return {
    type: constants.UPDATE_ACTIVITY_ERROR,
  };
};

export const deleteImsDoc = (data) => {
  return {
    type: constants.DELETE_IMS_DOC,
    data: data,
  };
};

export const deleteImsDocSuccess = (data) => {
  return {
    type: constants.DELETE_IMS_DOC_SUCCESS,
    data: data,
  };
};

export const deleteImsDocError = () => {
  return {
    type: constants.DELETE_IMS_DOC_ERROR,
  };
};

export const createImsDoc = (data) => {
  return {
    type: constants.CREATE_IMS_DOC,
    data: data,
  };
};

export const createImsDocSuccess = (data) => {
  return {
    type: constants.CREATE_IMS_DOC_SUCCESS,
    data: data,
  };
};

export const createImsDocError = () => {
  return {
    type: constants.CREATE_IMS_DOC_ERROR,
  };
};

export const deleteImsDocAttach = (data) => {
  return {
    type: constants.DELETE_IMS_DOC_ATTACH,
    data: data,
  };
};

export const deleteImsDocAttachSuccess = (data) => {
  return {
    type: constants.DELETE_IMS_DOC_ATTACH_SUCCESS,
    data: data,
  };
};

export const deleteImsDocAttachError = () => {
  return {
    type: constants.DELETE_IMS_DOC_ATTACH_ERROR,
  };
};

export const updateIsAddIsUpdate = (data) => {
  return {
    type: constants.UPDATE_ISADD_ISUPDATE,
    data,
  };
};

export const addActivityAttach = (data) => {
  return {
    type: constants.ADD_IMS_DOC_ATTACH,
    data: data,
  };
};

export const fetchBatchUpdate = (data) => {
  return {
    type: constants.FETCH_BATCH_UPDATE,
    data: data,
  };
};

export const fetchBatchUpdateSuccess = (data) => {
  return {
    type: constants.FETCH_BATCH_UPDATE_SUCCESS,
    data: data,
  };
};

export const fetchBatchUpdateError = () => {
  return {
    type: constants.FETCH_BATCH_UPDATE_ERROR,
  };
};

export const setPillarData = (data) => {
  return {
    type: constants.SET_PILLARS_DATA,
    data,
  };
};
