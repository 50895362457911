const UPDATE_INTERVENTION_TYPE = 'UPDATE_INTERVENTION_TYPE';
const UPDATE_INTERVENTION_TYPE_SUCCESS = 'UPDATE_INTERVENTION_TYPE_SUCCESS';
const UPDATE_INTERVENTION_TYPE_ERROR = 'UPDATE_INTERVENTION_TYPE_ERROR';
const DELETE_INTERVENTION_TYPE = 'DELETE_INTERVENTION_TYPES';
const DELETE_INTERVENTION_TYPE_SUCCESS = 'DELETE_INTERVENTION_TYPE_SUCCESS';
const DELETE_INTERVENTION_TYPE_ERROR = 'DELETE_INTERVENTION_TYPE_ERROR';
const CREATE_INTERVENTION_TYPE = 'CREATE_INTERVENTION_TYPE';
const CREATE_INTERVENTION_TYPE_SUCCESS = 'CREATE_INTERVENTION_TYPE_SUCCESS';
const CREATE_INTERVENTION_TYPE_ERROR = 'CREATE_INTERVENTION_TYPE_ERROR';
const FETCH_ALL_INTERVENTION_TYPES = 'FETCH_ALL_INTERVENTION_TYPES';
const FETCH_ALL_INTERVENTION_TYPES_SUCCESS =
  'FETCH_ALL_INTERVENTION_TYPES_SUCCESS';
const FETCH_ALL_INTERVENTION_TYPES_ERROR = 'FETCH_ALL_INTERVENTION_TYPES_ERROR';
const INTERVENTION_TYPE_IS_EDIT = 'INTERVENTION_TYPE_IS_EDIT';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';
const FETCH_INTERVENTION_TYPE = 'FETCH_INTERVENTION_TYPE';
const INTERVENTION_TYPE_CREATION_CLEANUP_FUNC =
  'INTERVENTION_TYPE_CREATION_CLEANUP_FUNC';
const FETCH_INTERVENTION_TYPE_SUCCESS = 'FETCH_INTERVENTION_TYPE_SUCCESS';
const FETCH_INTERVENTION_TYPE_ERROR = 'FETCH_INTERVENTION_TYPE_ERROR';

export {
  INTERVENTION_TYPE_IS_EDIT,
  FETCH_ALL_INTERVENTION_TYPES,
  FETCH_ALL_INTERVENTION_TYPES_SUCCESS,
  FETCH_ALL_INTERVENTION_TYPES_ERROR,
  CREATE_INTERVENTION_TYPE,
  CREATE_INTERVENTION_TYPE_SUCCESS,
  CREATE_INTERVENTION_TYPE_ERROR,
  UPDATE_INTERVENTION_TYPE,
  UPDATE_INTERVENTION_TYPE_SUCCESS,
  UPDATE_INTERVENTION_TYPE_ERROR,
  DELETE_INTERVENTION_TYPE,
  DELETE_INTERVENTION_TYPE_SUCCESS,
  DELETE_INTERVENTION_TYPE_ERROR,
  IS_ADDING_UPDATE,
  FETCH_INTERVENTION_TYPE,
  INTERVENTION_TYPE_CREATION_CLEANUP_FUNC,
  FETCH_INTERVENTION_TYPE_SUCCESS,
  FETCH_INTERVENTION_TYPE_ERROR,
};
