const FETCH_PROJECT_DOCS = "FETCH_PROJECT_DOCS";
const FETCH_PROJECT_DOCS_SUCCESS = "FETCH_PROJECT_DOCS_SUCCESS";
const FETCH_PROJECT_DOCS_ERROR = "FETCH_PROJECT_DOCS_ERROR";
const FETCH_PROJECT_ACTIVITIES = "FETCH_PROJECT_ACTIVITIES";
const FETCH_PROJECT_ACTIVITIES_SUCCESS = "FETCH_PROJECT_ACTIVITIES_SUCCESS";
const FETCH_PROJECT_ACTIVITIES_ERROR = "FETCH_PROJECT_ACTIVITIES_ERROR";
const FETCH_PROJECT_ACTIVITIES_GRAPH_DATA =
  "FETCH_PROJECT_ACTIVITIES_GRAPH_DATA";
const FETCH_PROJECT_ACTIVITIES_GRAPH_DATA_SUCCESS =
  "FETCH_PROJECT_ACTIVITIES_GRAPH_DATA_SUCCESS";
const FETCH_PROJECT_ACTIVITIES_GRAPH_DATA_ERROR =
  "FETCH_PROJECT_ACTIVITIES_GRAPH_DATA_ERROR";
const UPDATE_PROJECT_ACTIVITY = "UPDATE_PROJECT_ACTIVITY";
const UPDATE_PROJECT_ACTIVITY_SUCCESS = "UPDATE_PROJECT_ACTIVITY_SUCCESS";
const UPDATE_PROJECT_ACTIVITY_ERROR = "UPDATE_PROJECT_ACTIVITY_ERROR";
const FETCH_PROJECT_ACTIVITIES_SEARCH_DATA =
  "FETCH_PROJECT_ACTIVITIES_SEARCH_DATA";
const FETCH_PROJECT_ACTIVITIES_SEARCH_DATA_SUCCESS =
  "FETCH_PROJECT_ACTIVITIES_SEARCH_DATA_SUCCESS";
const FETCH_PROJECT_ACTIVITIES_SEARCH_DATA_ERROR =
  "FETCH_PROJECT_ACTIVITIES_SEARCH_DATA_ERROR";
const DELETE_PROJECT_DOC = "DELETE_PROJECT_DOC";
const DELETE_PROJECT_DOC_SUCCESS = "DELETE_PROJECT_DOC_SUCCESS";
const DELETE_PROJECT_DOC_ERROR = "DELETE_PROJECT_DOC_ERROR";
const CREATE_PROJECT_DOC = "CREATE_PROJECT_DOC";
const CREATE_PROJECT_DOC_SUCCESS = "CREATE_PROJECT_DOC_SUCCESS";
const CREATE_PROJECT_DOC_ERROR = "CREATE_PROJECT_DOC_ERROR";
const DELETE_PROJECT_DOC_ATTACH = "DELETE_PROJECT_DOC_ATTACH";
const DELETE_PROJECT_DOC_ATTACH_SUCCESS = "DELETE_PROJECT_DOC_ATTACH_SUCCESS";
const DELETE_PROJECT_DOC_ATTACH_ERROR = "DELETE_PROJECT_DOC_ATTACH_ERROR";
const UPDATE_PROJECT_ISADD_ISUPDATE = "UPDATE_PROJECT_ISADD_ISUPDATE";
const ADD_PROJECT_DOC_ATTACH = "ADD_PROJECT_DOC_ATTACH";
const FETCH_PROJECT_BATCH_UPDATE = "FETCH_PROJECT_BATCH_UPDATE";
const FETCH_PROJECT_BATCH_UPDATE_SUCCESS = "FETCH_PROJECT_BATCH_UPDATE_SUCCESS";
const FETCH_PROJECT_BATCH_UPDATE_ERROR = "FETCH_PROJECT_BATCH_UPDATE_ERROR";

export {
  FETCH_PROJECT_DOCS,
  FETCH_PROJECT_DOCS_SUCCESS,
  FETCH_PROJECT_DOCS_ERROR,
  FETCH_PROJECT_ACTIVITIES,
  FETCH_PROJECT_ACTIVITIES_SUCCESS,
  FETCH_PROJECT_ACTIVITIES_ERROR,
  FETCH_PROJECT_ACTIVITIES_GRAPH_DATA,
  FETCH_PROJECT_ACTIVITIES_GRAPH_DATA_SUCCESS,
  FETCH_PROJECT_ACTIVITIES_GRAPH_DATA_ERROR,
  FETCH_PROJECT_ACTIVITIES_SEARCH_DATA,
  FETCH_PROJECT_ACTIVITIES_SEARCH_DATA_SUCCESS,
  FETCH_PROJECT_ACTIVITIES_SEARCH_DATA_ERROR,
  UPDATE_PROJECT_ACTIVITY,
  UPDATE_PROJECT_ACTIVITY_SUCCESS,
  UPDATE_PROJECT_ACTIVITY_ERROR,
  DELETE_PROJECT_DOC,
  DELETE_PROJECT_DOC_SUCCESS,
  DELETE_PROJECT_DOC_ERROR,
  CREATE_PROJECT_DOC,
  CREATE_PROJECT_DOC_SUCCESS,
  CREATE_PROJECT_DOC_ERROR,
  DELETE_PROJECT_DOC_ATTACH,
  DELETE_PROJECT_DOC_ATTACH_SUCCESS,
  DELETE_PROJECT_DOC_ATTACH_ERROR,
  UPDATE_PROJECT_ISADD_ISUPDATE,
  ADD_PROJECT_DOC_ATTACH,
  FETCH_PROJECT_BATCH_UPDATE,
  FETCH_PROJECT_BATCH_UPDATE_SUCCESS,
  FETCH_PROJECT_BATCH_UPDATE_ERROR,
};
