import * as constants from './constants';

export const fetchCoachTypes = (data) => {
  return {
    type: constants.FETCH_COACH_TYPES,
    data,
  };
};

export const fetchCoachTypesSuccess = (data) => {
  return {
    type: constants.FETCH_COACH_TYPES_SUCCESS,
    data: data,
  };
};

export const fetchCoachTypesError = (data) => {
  return {
    type: constants.FETCH_COACH_TYPES_ERROR,
    data: data,
  };
};

export const updateCoachTypes = (data) => {
  return {
    type: constants.UPDATE_COACH_TYPES,
    data: data,
  };
};

export const updateCoachTypesSuccess = (data) => {
  return {
    type: constants.UPDATE_COACH_TYPES_SUCCESS,
    data: data,
  };
};

export const updateCoachTypesError = () => {
  return {
    type: constants.UPDATE_COACH_TYPES_ERROR,
  };
};

export const updateCoachTypesOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_COACH_TYPES,
    data: data,
  };
};

export const updateCoachTypesOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_COACH_TYPES_SUCCESS,
    data: data,
  };
};

export const updateCoachTypesOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_COACH_TYPES_ERROR,
  };
};

export const deleteCoachTypes = (data) => {
  return {
    type: constants.DELETE_COACH_TYPES,
    data: data,
  };
};

export const deleteCoachTypesSuccess = (data) => {
  return {
    type: constants.DELETE_COACH_TYPES_SUCCESS,
    data: data,
  };
};

export const deleteCoachTypesError = () => {
  return {
    type: constants.DELETE_COACH_TYPES_ERROR,
  };
};

export const createCoachTypes = (data, id) => {
  return {
    type: constants.CREATE_COACH_TYPES,
    data: data,
  };
};

export const createCoachTypesSuccess = (data) => {
  return {
    type: constants.CREATE_COACH_TYPES_SUCCESS,
    data: data,
  };
};

export const createCoachTypesError = (id) => {
  return {
    type: constants.CREATE_COACH_TYPES_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
