const FETCH_PROJECT_STATUSES= "FETCH_PROJECT_STATUSES";
const FETCH_PROJECT_STATUSES_SUCCESS = "FETCH_PROJECT_STATUSES_SUCCESS";
const FETCH_PROJECT_STATUSES_ERROR = "FETCH_PROJECT_STATUSES_ERROR";
const UPDATE_PROJECT_STATUSES = "UPDATE_PROJECT_STATUSES";
const UPDATE_PROJECT_STATUSES_SUCCESS = "UPDATE_PROJECT_STATUSES_SUCCESS";
const UPDATE_PROJECT_STATUSES_ERROR = "UPDATE_PROJECT_STATUSES_ERROR";
const DELETE_PROJECT_STATUSES = "DELETE_PROJECT_STATUSES";
const DELETE_PROJECT_STATUSES_SUCCESS = "DELETE_PROJECT_STATUSES_SUCCESS";
const DELETE_PROJECT_STATUSES_ERROR = "DELETE_PROJECT_STATUSES_ERROR";
const CREATE_PROJECT_STATUSES = "CREATE_PROJECT_STATUSES";
const CREATE_PROJECT_STATUSES_SUCCESS = "CREATE_PROJECT_STATUSES_SUCCESS";
const CREATE_PROJECT_STATUSES_ERROR = "CREATE_PROJECT_STATUSES_ERROR";
const IS_ADDING_UPDATE = "IS_ADDING_UPDATE";
const UPDATE_ORDER = "UPDATE_ORDER";
const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
const UPDATE_ORDER_ERROR = "UPDATE_ORDER_ERROR";

export {
  FETCH_PROJECT_STATUSES,
  FETCH_PROJECT_STATUSES_SUCCESS,
  FETCH_PROJECT_STATUSES_ERROR,
  UPDATE_PROJECT_STATUSES,
  UPDATE_PROJECT_STATUSES_SUCCESS,
  UPDATE_PROJECT_STATUSES_ERROR,
  DELETE_PROJECT_STATUSES,
  DELETE_PROJECT_STATUSES_SUCCESS,
  DELETE_PROJECT_STATUSES_ERROR,
  CREATE_PROJECT_STATUSES,
  CREATE_PROJECT_STATUSES_SUCCESS,
  CREATE_PROJECT_STATUSES_ERROR,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_ERROR,
  IS_ADDING_UPDATE,
};
