import * as constants from "./constants";

export const fetchProjectRevisions = (data) => {
  return {
    type: constants.FETCH_PROJECT_REVISIONS,
    data,
  };
};

export const fetchProjectRevisionsSuccess = (data) => {
  return {
    type: constants.FETCH_PROJECT_REVISIONS_SUCCESS,
    data: data,
  };
};

export const fetchProjectRevisionsError = (data) => {
  return {
    type: constants.FETCH_PROJECT_REVISIONS_ERROR,
    data: data,
  };
};

export const createProjectRevision = (data) => {
  return {
    type: constants.CREATE_PROJECT_REVISIONS,
    data: data,
  };
};

export const createProjectRevisionSuccess = (data) => {
  return {
    type: constants.CREATE_PROJECT_REVISIONS_SUCCESS,
    data: data,
  };
};

export const createProjectRevisionError = () => {
  return {
    type: constants.CREATE_PROJECT_REVISIONS_ERROR,
  };
};

export const updateProjectRevision = (data) => {
  return {
    type: constants.UPDATE_PROJECT_REVISIONS,
    data: data,
  };
};

export const updateProjectRevisionSuccess = (data) => {
  return {
    type: constants.UPDATE_PROJECT_REVISIONS_SUCCESS,
    data: data,
  };
};

export const updateProjectRevisionError = () => {
  return {
    type: constants.UPDATE_PROJECT_REVISIONS_ERROR,
  };
};

export const deleteProjectRevision = (data) => {
  return {
    type: constants.DELETE_PROJECT_REVISIONS,
    data: data,
  };
};

export const deleteProjectRevisionSuccess = (data) => {
  return {
    type: constants.DELETE_PROJECT_REVISIONS_SUCCESS,
    data: data,
  };
};

export const deleteProjectRevisionError = () => {
  return {
    type: constants.DELETE_PROJECT_REVISIONS_ERROR,
  };
};

export const deleteProjectRevisionAttach = (data) => {
  return {
    type: constants.DELETE_PROJECT_REVISIONS_ATTACH,
    data: data,
  };
};

export const deleteProjectRevisionAttachSuccess = (data) => {
  return {
    type: constants.DELETE_PROJECT_REVISIONS_ATTACH_SUCCESS,
    data: data,
  };
};

export const deleteProjectRevisionAttachError = () => {
  return {
    type: constants.DELETE_PROJECT_REVISIONS_ATTACH_ERROR,
  };
};

export const updateProjectRevisionsIsAddIsUpdate = (data) => {
  return {
    type: constants.UPDATE_PROJECT_REVISIONS_ISADD_ISUPDATE,
    data,
  };
};

export const addProjectRevisionAttach = (data) => {
  return {
    type: constants.ADD_PROJECT_REVISIONS_ATTACH,
    data: data,
  };
};
