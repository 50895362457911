const FETCH_SITE_STATUSES= "FETCH_SITE_STATUSES";
const FETCH_SITE_STATUSES_SUCCESS = "FETCH_SITE_STATUSES_SUCCESS";
const FETCH_SITE_STATUSES_ERROR = "FETCH_SITE_STATUSES_ERROR";
const UPDATE_SITE_STATUSES = "UPDATE_SITE_STATUSES";
const UPDATE_SITE_STATUSES_SUCCESS = "UPDATE_SITE_STATUSES_SUCCESS";
const UPDATE_SITE_STATUSES_ERROR = "UPDATE_SITE_STATUSES_ERROR";
const DELETE_SITE_STATUSES = "DELETE_SITE_STATUSES";
const DELETE_SITE_STATUSES_SUCCESS = "DELETE_SITE_STATUSES_SUCCESS";
const DELETE_SITE_STATUSES_ERROR = "DELETE_SITE_STATUSES_ERROR";
const CREATE_SITE_STATUSES = "CREATE_SITE_STATUSES";
const CREATE_SITE_STATUSES_SUCCESS = "CREATE_SITE_STATUSES_SUCCESS";
const CREATE_SITE_STATUSES_ERROR = "CREATE_SITE_STATUSES_ERROR";
const UPDATE_ORDER_SITE_STATUS = "UPDATE_ORDER_SITE_STATUS";
const UPDATE_ORDER_SITE_STATUS_SUCCESS = "UPDATE_ORDER_SITE_STATUS_SUCCESS";
const UPDATE_ORDER_SITE_STATUS_ERROR = "UPDATE_ORDER_SITE_STATUS_ERROR";
const IS_ADDING_UPDATE = "IS_ADDING_UPDATE";

export {
  FETCH_SITE_STATUSES,
  FETCH_SITE_STATUSES_SUCCESS,
  FETCH_SITE_STATUSES_ERROR,
  UPDATE_SITE_STATUSES,
  UPDATE_SITE_STATUSES_SUCCESS,
  UPDATE_SITE_STATUSES_ERROR,
  DELETE_SITE_STATUSES,
  DELETE_SITE_STATUSES_SUCCESS,
  DELETE_SITE_STATUSES_ERROR,
  CREATE_SITE_STATUSES,
  CREATE_SITE_STATUSES_SUCCESS,
  CREATE_SITE_STATUSES_ERROR,
  UPDATE_ORDER_SITE_STATUS,
  UPDATE_ORDER_SITE_STATUS_SUCCESS,
  UPDATE_ORDER_SITE_STATUS_ERROR,
  IS_ADDING_UPDATE,
};
