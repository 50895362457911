import { useEffect } from 'react';
import { List, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import './index.less';
import moment from 'moment';
import {
  fetchAllNotifications,
  fetchUnreadNotifications,
  updateNotification,
} from './notificationsRedux/notificationActions';

const handleDate = (date) => {
  let REFERENCE = moment();
  let TODAY = REFERENCE.clone().startOf('day');
  let YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
  // let A_WEEK_OLD = REFERENCE.clone().subtract(7, "days").startOf("day");

  const isToday = (momentDate) => {
    return momentDate.isSame(TODAY, 'd');
  };

  if (isToday(moment(date))) {
    return 'Today';
  }

  const isYesterday = (momentDate) => {
    return momentDate.isSame(YESTERDAY, 'd');
  };

  if (isYesterday(moment(date))) {
    return 'Yesterday';
  }
  // const isWithinAWeek = (momentDate) => {
  //   return momentDate.isAfter(A_WEEK_OLD);
  // };
  // if (isToday(date)) {
  //   return "Today";
  // }
  // const isTwoWeeksOrMore = (momentDate) => {
  //   return !isWithinAWeek(momentDate);
  // };

  return moment(date).format('DD MMMM YYYY');
};

const Notifications = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const { notificationLoading, allNotifications } = useSelector(
    (state) => state.notificationsReducer
  );

  const { id } = useSelector((state) => state.authReducer.user);

  useEffect(() => {
    if (visible) {
      // dispatch(fetchAllNotifications(id));
      // dispatch(fetchUnreadNotifications());
    }
  }, [visible]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {};

  const handleCancel = () => {
    setVisible(false);
  };

  const linkHandler = ({ is_read, item_id }) => {
    setVisible(!visible);
    if (!is_read) {
      dispatch(updateNotification({ id: item_id, user_id: id }));
    }
  };

  return (
    <Modal
      className='notification-modal'
      title={<div>Notifications</div>}
      visible={visible}
      width={206} // 260
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      {allNotifications.map((el) => {
        const { date, notifications } = el;
        return (
          <List
            // style={{ backgroundColor: "#fff", width: "206px" }}
            size='small'
            footer={<></>}
            header={<span>{handleDate(date)}</span>}
            loading={notificationLoading}
            bordered
            dataSource={notifications}
            className='notifications-list'
            renderItem={(item) => {
              const { is_read, created_at, id, title } = item;
              let updatedTime = moment
                .utc(created_at, 'YYYY-MM-DD HH:mm:ss.SSS')
                .local()
                .toDate();
              let finalTime = moment(updatedTime).format('HH:mm');
              let pathname = '/';
              let state = {};

              switch (item.notification_on_type) {
                case 'Npo':
                  const { notification_on_id } = item;
                  pathname = `/npo-setup/update/${notification_on_id}`;

                  break;
                case 'DocumentControl':
                case 'Revision':
                case 'Distribution':
                  if (item?.npo_id > 0) {
                    const { npo_id, document_number } = item;
                    pathname = `/npo/document-control/${npo_id}`;
                    state['document_number'] = document_number;
                  } else {
                    const { document_number } = item;
                    pathname = `ims/document-control`;
                    state['document_number'] = document_number;
                  }
                  break;

                default:
                  break;
              }
              let updatedItem = { ...item, item_id: id };

              return (
                <Link
                  to={pathname}
                  state={{ ...state }}
                  onClick={() => linkHandler(updatedItem)}
                >
                  <List.Item
                    key={id}
                    onClick={() => linkHandler({ ...item, item_id: id })}
                    className={`${!is_read && 'unread-listitem'}`}
                  >
                    <div className='time-div'>{finalTime}</div>
                    <div
                      className={`message-body ${!is_read && 'unread-message'}`}
                    >
                      {title}
                    </div>
                  </List.Item>
                </Link>
              );
            }}
          />
        );
      })}
    </Modal>
  );
};

export default Notifications;
