import * as constants from './constants';

export const fetchNpoStatuses = (data) => {
  return {
    type: constants.FETCH_NPO_STATUSES,
    data,
  };
};

export const fetchNpoStatusesSuccess = (data) => {
  return {
    type: constants.FETCH_NPO_STATUSES_SUCCESS,
    data: data,
  };
};

export const fetchNpoStatusesError = (data) => {
  return {
    type: constants.FETCH_NPO_STATUSES_ERROR,
    data: data,
  };
};

export const updateNpoStatuses = (data) => {
  return {
    type: constants.UPDATE_NPO_STATUSES,
    data: data,
  };
};

export const updateNpoStatusesSuccess = (data) => {
  return {
    type: constants.UPDATE_NPO_STATUSES_SUCCESS,
    data: data,
  };
};

export const updateNpoStatusesError = () => {
  return {
    type: constants.UPDATE_NPO_STATUSES_ERROR,
  };
};

export const updateNpoOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_NPO_STATUS,
    data: data,
  };
};

export const updateNpoOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_NPO_STATUS_SUCCESS,
    data: data,
  };
};

export const updateNpoOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_NPO_STATUS_ERROR,
  };
};

export const deleteNpoStatuses = (data) => {
  return {
    type: constants.DELETE_NPO_STATUSES,
    data: data,
  };
};

export const deleteNpoStatusesSuccess = (data) => {
  return {
    type: constants.DELETE_NPO_STATUSES_SUCCESS,
    data: data,
  };
};

export const deleteNpoStatusesError = () => {
  return {
    type: constants.DELETE_NPO_STATUSES_ERROR,
  };
};

export const createNpoStatuses = (data, id) => {
  return {
    type: constants.CREATE_NPO_STATUSES,
    data: data,
  };
};

export const createNpoStatusesSuccess = (data) => {
  return {
    type: constants.CREATE_NPO_STATUSES_SUCCESS,
    data: data,
  };
};

export const createNpoStatusesError = (id) => {
  return {
    type: constants.CREATE_NPO_STATUSES_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
